<template>
  <v-row>
    <v-col cols="9">
      <admin-data-table ref="dataTable" fetch-url="admin/ventas/ticket/" :headers="headers" :get-stats="true"
        :filters="{ fecha__date__gte: this.dateFrom, fecha__date__lte: this.dateTo }"
        @create="readonly=false; selectedItem=null; formDialog=true"
        @read="(item) => {readonly=true; selectedItem=item; formDialog=true;}"
        @update="(item) => {readonly=false; selectedItem=item; formDialog=true;}"
        @stats="(value) => stats = value"
      >
        <template #extra-filters>
          <date-picker v-model="dateFrom" :label="$t('Fecha desde')" dense filled  append-icon="mdi-calendar" hide-details clearable />
          <date-picker v-model="dateTo" :label="$t('Fecha hasta')" dense filled append-icon="mdi-calendar" hide-details clearable />
        </template>
        <template #[`item.fecha`]="{ item }">
          <span class="font-italic font-weight-light">{{ item.fecha|dateFromNow|capitalize }}</span>
        </template>
        <template #[`item.importe`]="{ item }">{{ item.lineas|totalAmount|money }}</template>
        <template #dialogs>
          <v-dialog v-model="formDialog" max-width="700">
            <ficha-ticket :readonly="readonly" :value="selectedItem" @close="formDialog=false" @done="() => { $refs.dataTable.fetch() }" />
          </v-dialog>
        </template>
        <template #extra-actions="{ item }">
          <v-icon small class="mr-2" @click.stop="$printTicket(item.id, gift=true)">mdi-gift</v-icon>
          <v-icon small class="mr-2" @click.stop="$printTicket(item.id)">mdi-printer</v-icon>
        </template>
      </admin-data-table>
    </v-col>
    <v-col cols="3">
      <v-card>
        <v-card-text>
          <div class="text-h6 secondary--text">{{ stats.count }} tickets</div>
          <div class="text-h4 primary--text">{{ stats.total|money }}</div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AdminDataTable from '@/components/utilities/AdminDataTable.vue'
import DatePicker from '@/components/utilities/DatePicker.vue'
import FichaTicket from '@/components/ventas/FichaTicket.vue'

export default {
  name: "Tickets",
  components: { AdminDataTable, DatePicker, FichaTicket, },
  data: function() {
    return {
      dateFrom: null,
      dateTo: null,
      formDialog: false,
      selectedItem: null,
      readonly: false,
      stats: {},
      headers: [
          { value: 'id', text: '#', align: 'center', sortable: false, divider: true, width: 80, },
          { value: 'fecha', text: this.$i18n.t('Fecha'), align: 'left', },
          { value: 'importe', text: this.$i18n.t('Importe'), align: 'right', sortable: false, },
          { value: 'actions', text: '', align: 'right', sortable: false, },
      ],
    }
  },
}
</script>

<style scoped>
</style>
