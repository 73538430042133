<template>
  <v-menu ref="menu" v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition" offset-y min-width="auto">
    <template #activator="{ on }">
      <v-text-field v-model="date" readonly v-bind="$attrs" v-on="on"/>
    </template>
    <v-date-picker v-if="!readonly" no-title v-model="date" @input="menu = false" :locale="$i18n.locale" :readonly="readonly" />
  </v-menu>
</template>

<script>
  export default {
    props: ["value", "readonly", ],
    data() {
      return {
        date: this.value,
        menu: false,
      }
    },
    watch: {
      date(newValue) {
        this.$emit("input", newValue);
      }
    }
  }
</script>

<style scoped>
</style>