<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-fade-transition appear>
        <v-badge overlap color="primary darken-4" bottom bordered offset-x="25" :content="shortcut" v-if="shortcut">
          <v-btn large color="primary" dark class="mx-1" v-bind="attrs" v-on="on">{{ $t("Borrar todo") }}</v-btn>
        </v-badge>
        <v-btn large color="primary" dark class="mx-1" v-bind="attrs" v-on="on" v-else>{{ $t("Borrar todo") }}</v-btn>
      </v-fade-transition>
    </template>
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>{{ $t("¿Borrar todo?") }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{ $t("Cancelar") }}</v-btn>
        <v-btn color="primary" text @click="$emit('done')">{{ $t("Confirmar") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LimpiarTpv",
  props: ["shortcut"],
  data: () => {
    return {
      dialog: false,
    }
  },
  methods: {
  },
}
</script>

<style scoped></style>
