import * as moment from 'moment'; 

moment.locale("es");

function amount(linea) {
    return linea.cantidad * linea.precio * (100 - linea.descuento) / 100;
}

export default {
  default: (value, defaultValue) => {
    return value?value:defaultValue;
  },
  money: (value) => {
    let symbol = "€";
    let decimals = 2;
    return (value != null)?`${parseFloat(value).toFixed(decimals)}\xa0${symbol}`:'';
  },
  percentage: (value) => {
    let decimals = 2;
    return (value > 0.01 | value < -0.01)?`${parseFloat(value).toFixed(decimals)}\xa0%`:'';
  },
  capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  dateFromNow: (value) => {
    return moment(value).calendar();
  },
  dateShort: (value) => {
    return moment(value).format("YYYY/MM/DD");
  },
  timeShort: (value) => {
    return moment(value).format("HH:MM");
  },
  dateLong: (value) => {
    return moment(value).format("LL");
  },
  dateTimeLong: (value) => {
    return moment(value).format("LLL");
  },
  round(number, decimals=2) {
    return Math.round(number * 10**decimals) / 10**decimals;
  },
  amount,
  totalAmount(lineas) {
    return lineas.reduce((partial, obj) => partial + amount(obj), 0);
  },
}
