<template>
  <div>
    <v-data-table v-bind="$attrs" :items="lineas" :headers="headers" class="striped"
      disable-sort disable-pagination disable-filtering hide-default-footer
    >
      <template #top>
        <div class="d-flex align-center" v-if="!readonly">
          <buscador-productos ref="search" @input="addProducto" />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab tile text color="primary" v-on="on" v-bind="attrs" @click="addProducto(empty, checkExistent=false)">
                <v-icon>mdi-gesture</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("Nueva línea libre") }}</span>
          </v-tooltip>
        </div>
      </template>
      <template #foot v-if="showTotal">
        <td colspan="3">
          <slot name="foot-space" />
        </td>
        <td colspan="3">
          <v-text-field class="center-input my-3" :label="$t('Total')" :value="lineas|totalAmount|money" outlined readonly />
        </td>
      </template>
      <template #[`item.cantidad`]="{ item }">
        <v-edit-dialog v-if="!readonly">
          {{ item.cantidad }}
          <template v-slot:input>
            <v-text-field :value="item.cantidad" @input="(value) => setCantidad(item, value)" type="number" />
          </template>
        </v-edit-dialog>
        <span v-else>{{ item.cantidad }}</span>
      </template>
      <template #[`item.descripcion`]="{ item }">
        <v-edit-dialog v-if="!readonly">
          <v-chip class="font-weight-bold" color="success" v-if="isPromo(item)" x-small>PROMO</v-chip>
          <span class="mx-5">{{ item.descripcion }}</span>
          <v-icon small v-if="!readonly" @click.stop="editProduct = item.producto; $refs.fichaProducto.dialog = true">mdi-pencil</v-icon>
          <template v-slot:input>
            <v-text-field v-model="item.descripcion" />
          </template>
        </v-edit-dialog>
        <span v-else>{{ item.descripcion }}</span>
      </template>
      <template #[`item.precio`]="{ index, item }">
        <v-edit-dialog v-if="!readonly" @save="$set(importes, index, getImporte(item))">
          {{ item.precio|money }}
          <template v-slot:input>
            <v-text-field v-model="item.precio" type="number" step="0.01" suffix="€" />
          </template>
        </v-edit-dialog>
        <span v-else>{{ item.precio|money }}</span>
      </template>
      <template #[`item.descuento`]="{ index, item }">
        <v-edit-dialog v-if="!readonly" @save="$set(importes, index, getImporte(item))">
          {{ item.descuento|percentage }}
          <template v-slot:input>
            <v-text-field v-model="item.descuento" type="number" suffix="%" />
          </template>
        </v-edit-dialog>
        <span v-else>{{ item.descuento|percentage }}</span>
      </template>
      <template #[`item.importe`]="{ index, item }">
        <v-edit-dialog v-if="!readonly" @save="setImporte(item, importes[index])">
          {{ importes[index]|money }}
          <template v-slot:input>
            <v-text-field v-model="importes[index]" type="number" step=0.1 suffix="€" />
          </template>
        </v-edit-dialog>
        <span v-else>{{ importes[index]|money }}</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon small v-if="!readonly" @click.stop="lineas.splice(lineas.indexOf(item), 1)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <ficha-producto ref="fichaProducto" :value="editProduct" @done="editProduct = null"/>
  </div>
</template>

<script>
import BuscadorProductos from "@/components/utilities/BuscadorProductos";
import FichaProducto from '@/components/inventario/FichaProducto.vue'

export default {
  name: "TablaLineas",
  components: { BuscadorProductos, FichaProducto, },
  props: {
    value: {
      type: Array,
      default() { return [] },
    },
    readonly: {
      type: Boolean,
      default: false,   
    },
    showTotal: {
      type: Boolean,
      default: true,   
    },
  },
  data: function() {
    return {
      lineas: [],
      importes: [],
      headers: [
          { value: 'cantidad', text: this.$i18n.t('Cantidad'), align: 'center', },
          { value: 'descripcion', text: this.$i18n.t('Descripción'), align: 'left', },
          { value: 'precio', text: this.$i18n.t('PVP'), align: 'right', },
          { value: 'descuento', text: this.$i18n.t('Descuento'), align: 'right' },
          { value: 'importe', text: this.$i18n.t('Importe'), align: 'right', },
          { value: 'actions', text: '', align: 'right', },
      ],
      empty: {
        id: null,
        descripcion: this.$i18n.t("OTROS"),
        pvp: 0,
      },
      editProduct: null,
    }
  },
  methods: {
    addProducto(producto, checkExistent=true) {
      var linea;
      if ((checkExistent) && (this.lineas.map((obj) => obj.producto).indexOf(producto.id) > -1)) {
        linea = this.lineas.find((obj) => obj.producto == producto.id)
        linea.cantidad += 1;
      } else {
        linea = {
          producto: producto.id,
          descripcion: producto.descripcion,
          cantidad: 1,
          precio: producto.pvp,
          descuento: 0,
          promociones: producto.promociones,
        }
        this.lineas.push(linea);
      }
      this.checkPromos(linea);
      this.importes[this.lineas.indexOf(linea)] = this.getImporte(linea);
    },
    checkPromos(linea) {
      if (linea.promociones) {
        linea.promociones.forEach((promo) => {
          if (promo.cantidad_desde <= linea.cantidad) {
            linea.descuento = promo.descuento
          }
        });
      }
    },
    getImporte(linea) {
      return this.$options.filters.round((linea.precio * (1-linea.descuento/100)) * linea.cantidad);
    },
    setImporte(linea, newValue) {
      linea.descuento = this.$options.filters.round((1 - (newValue/linea.cantidad)/linea.precio) * 100); 
    },
    setCantidad(linea, newValue) {
      linea.cantidad = newValue;
      this.checkPromos(linea);
    },
    isPromo(item) {
      return (item.promociones && item.promociones.length && (item.cantidad >= item.promociones[0].cantidad_desde))
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() { 
        this.lineas = this.value;
        this.importes = this.lineas.map(this.getImporte);
      },
    },
    lineas: {
      deep: true,
      handler() { this.$emit('input', this.lineas); },
    }
  },
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
.striped >>> .v-data-table__empty-wrapper {
  display: none;
}
.center-input >>> input {
  text-align: center;
}
</style>
