import { render, staticRenderFns } from "./Productos.vue?vue&type=template&id=63bd836a&scoped=true&"
import script from "./Productos.vue?vue&type=script&lang=js&"
export * from "./Productos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bd836a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VChip,VSelect})
