<template>
    <v-dialog v-model="dialog" max-width="500">
        <template v-slot:activator="{ on }">
            <v-btn text v-on="on"><v-icon>mdi-cog</v-icon></v-btn>
        </template>
        <v-card>
            <v-card-title class="mb-5">{{ $t('App Settings') }}</v-card-title>
            <v-card-text>
                <v-select :label="$t('Idioma')" v-model="settings.locale" :items="languages" outlined append-icon="mdi-translate" />
                <v-text-field :label="$t('URL Impresora Tickets')" v-model="settings.receiptPrinterUrl" outlined append-icon="mdi-printer" />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="dialog = false">{{ $t('Cerrar') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'AppSettings',
        data() {
            return {
                dialog: false,
                settings: this.$store.state.appSettings,
                languages: [
                    { value: 'es', text: 'Español' },
                    { value: 'ca', text: 'Català' },
                ]
            }
        },
        watch: {
            settings: {
                deep: true,
                handler(newValue) {
                    this.$root.$i18n.locale = newValue.locale;
                    this.$store.commit("appSettings/update", newValue);
                }
            }
        }
    }
</script>

<style scoped></style>
