export const alert = {
    namespaced: true,
    state: {
        active: false,
        message: null,
        type: null,
        timeout: 6000,
    },
    actions: {
        alert({ commit }, { message="", type="info", timeout=6000 } ) {
            commit('setMessage', { message, type, timeout });
            commit('setActive', true);
        },
        close({ commit }) {
            commit('setActive', false);
        }
    },
    mutations: {
        setMessage(state, { message, type, timeout }) {
            state.message = message;
            state.type = type;
            state.timeout = timeout;
        },
        setActive(state, value) {
            state.active = value;
        },
    }
}
