<template>
  <v-dialog v-model="dialog" :max-width="1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn large color="primary" outlined class="mx-1" v-bind="attrs" v-on="on">{{ $t("Apartados") }}</v-btn>
    </template>
    <v-card :loading="loading">
      <v-card-title>{{ $t('Apartados') }}</v-card-title>
      <v-card-text>
        <v-simple-table dense fixed-header height="400">
          <thead>
            <tr>
              <th class="text-left">{{ $t('Fecha') }}</th>
              <th class="text-left">{{ $t('Cliente') }}</th>
              <th class="text-right">{{ $t('Importe') }}</th>
              <th class="text-center">{{ $t('Pagado') }}</th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" @click="open(item)">
              <td class="text-left font-italic font-weight-light">{{ item.fecha|dateFromNow }}</td>
              <td class="text-left">{{ item.cliente?item.cliente.nombre:'' }}</td>
              <td class="text-right">{{ totalAmount(item)|money }}</td>
              <td class="text-center">
                <v-chip label color="green" dark small v-if="item.deposito == totalAmount(item)">{{ $t('Pagado') }}</v-chip>
                <span v-if="(item.deposito > 0) & (item.deposito < totalAmount(item))">{{ item.deposito|money }}</span>
              </td>
              <td class="text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="green" v-on="on" @click.stop="loadItem(item)">
                      <v-icon>mdi-location-enter</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Cargar al TPV') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="red" v-on="on" @click.stop="_delete(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Borrar') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{ $t("Ok") }}</v-btn>
      </v-card-actions>
      <v-dialog ref="itemDialog" v-model="itemDialog" width="600" v-if="selectedItem">
        <v-card class="item">
          <v-card-title></v-card-title>
          <v-card-text>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">{{ $t('Artículo') }}</th>
                  <th class="text-center">{{ $t('Cant.') }}</th>
                  <th class="text-right">{{ $t('PVP') }}</th>
                  <th class="text-right">{{ $t('Dto') }}</th>
                  <th class="text-right">{{ $t('Imp') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="sale in selectedItem.lineas" :key="sale.id" >
                  <td class="text-left">{{ sale.descripcion }}</td>
                  <td class="text-center">{{ sale.cantidad }}</td>
                  <td class="text-right">{{ sale.precio }}</td>
                  <td class="text-right">{{ sale.descuento|percentage }}</td>
                  <td class="text-right">{{ (sale.precio * sale.cantidad * (100 - sale.descuento) / 100)|money }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="itemDialog = false">{{ $t("Ok") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "UltimosItems",
  props: ["shortcut"],
  data: () => {
    return {
      dialog: false,
      loading: false,
      itemDialog: false,
      items: [],
      selectedItem: null,
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    open(item) {
      this.selectedItem = item;
      this.itemDialog = true;
    },
    totalAmount(item) {
      return this.$options.filters.totalAmount(item.lineas);
    },
    fetch() {
      this.loading = true;
      this.$apiRequest('get', 'admin/ventas/apartado/')
      .then(res => {
          const { results } = res.data;
          this.items = results;
      })
      .catch(err => {
          console.log(err)
      })
      .finally(() => (this.loading = false))
    },
    _delete(item) {
      this.loading = true;
      return this.$apiRequest('delete', `admin/ventas/apartado/${item.id}/`)
      .then(() => {
        this.items.splice(this.items.indexOf(item), 1);
        this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("Registro borrado. ¿Nunca vino a buscarlo? 😢")
        });
      }).catch(error => {
        this.$store.dispatch('alert/alert', {
          type: "warning",
          message: this.$t("App Error: {error}", { error: error.message }),
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    loadItem(item) {
      this.loading = true;
      return this.$apiRequest('delete', `admin/ventas/apartado/${item.id}/`)
      .then(() => {
        this.$emit("input", item)
        this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("Registro cargado. Ojo! Ya no está apartado.")
        });
      }).catch(error => {
        this.$store.dispatch('alert/alert', {
          type: "warning",
          message: this.$t("App Error: {error}", { error: error.message }),
        });
      }).finally(() => {
        this.loading = false;
        this.dialog = false;
      })
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue) this.fetch();
    },
  }
}
</script>

<style scoped>
</style>
