<template>
  <v-row>
    <v-col cols="9">
      <admin-data-table ref="dataTable" fetch-url="admin/ventas/albaran/" :headers="headers" :get-stats="true"
        :filters="{ fecha__date__gte: this.dateFrom, fecha__date__lte: this.dateTo, factura__isnull: this.filtroNoFacturados }"
        @create="readonly=false; selectedItem=null; formDialog=true"
        @read="(item) => {readonly=true; selectedItem=item; formDialog=true;}"
        @update="(item) => {readonly=false; selectedItem=item; formDialog=true;}"
        :editable="(item) => !item.factura"
        :deletable="(item) => !item.factura"
        @stats="(value) => stats = value"
      >
        <template #extra-filters>
          <v-select v-model="filtroNoFacturados" :label="$t('Estado')" :items="filtroNoFacturadosOptions" append-icon="mdi-receipt" filled dense hide-details />
          <date-picker v-model="dateFrom" :label="$t('Fecha desde')" dense filled  append-icon="mdi-calendar" hide-details clearable />
          <date-picker v-model="dateTo" :label="$t('Fecha hasta')" dense filled append-icon="mdi-calendar" hide-details clearable />
        </template>
        <template #[`item.fecha`]="{ item }">
          <span class="font-italic font-weight-light">{{ item.fecha|dateLong|capitalize }}</span>
        </template>
        <template #[`item.importe`]="{ item }">{{ item.lineas|totalAmount|money }}</template>
        <template #[`item.cliente__nombre`]="{ item }">{{ item.cliente.nombre }}</template>
        <template #[`item.factura`]="{ item }">
          <span v-if="item.factura">{{ $t('Factura') }}&nbsp;#{{ item.factura.id }}</span>
        </template>
        <template #dialogs>
          <v-dialog v-model="formDialog" max-width="800">
            <ficha-albaran :readonly="readonly" :value="selectedItem" @close="formDialog=false" @done="() => { $refs.dataTable.fetch() }" />
          </v-dialog>
        </template>
      </admin-data-table>
    </v-col>
    <v-col cols="3">
      <v-card>
        <v-card-text>
          <div class="text-h6 secondary--text">{{ stats.count }} albaranes</div>
          <div class="text-h4 primary--text">{{ stats.total|money }}</div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AdminDataTable from '@/components/utilities/AdminDataTable.vue'
import DatePicker from '@/components/utilities/DatePicker.vue'
import FichaAlbaran from '@/components/ventas/FichaAlbaran.vue'

export default {
  name: "Albaranes",
  components: { AdminDataTable, DatePicker, FichaAlbaran },
  data: function() {
    return {
      dateFrom: null,
      dateTo: null,
      filtroNoFacturados: true,
      formDialog: false,
      selectedItem: null,
      readonly: false,
      stats: {},
      filtroNoFacturadosOptions: [
        { value: null, text: this.$i18n.t('Todos') },
        { value: true, text: this.$i18n.t('No facturados') },
        { value: false, text: this.$i18n.t('Facturados') },
      ],
      headers: [
          { value: 'id', text: '#', align: 'center', sortable: false, divider: true, width: 80, },
          { value: 'cliente__nombre', text: this.$i18n.t('Cliente'), align: 'left' },
          { value: 'fecha', text: this.$i18n.t('Fecha'), align: 'left', },
          { value: 'factura', text: this.$i18n.t('Factura'), align: 'center', sortable: true },
          { value: 'importe', text: this.$i18n.t('Importe'), align: 'right', sortable: false },
          { value: 'actions', text: '', align: 'right', sortable: false, },
      ],
    }
  },
}
</script>

<style scoped>
</style>
