<template>
  <admin-data-table ref="dataTable" fetch-url="admin/inventario/proveedor/" :headers="headers" 
    @create="readonly=false; selectedItem=null; $refs.ficha.dialog=true"
    @read="(item) => {readonly=true; selectedItem=item; $refs.ficha.dialog=true;}"
    @update="(item) => {readonly=false; selectedItem=item; $refs.ficha.dialog=true;}"
  >
    <template #dialogs>
      <ficha-proveedor ref="ficha" :readonly="readonly" :value="selectedItem" @done="() => { $refs.dataTable.fetch() }" />
    </template>
  </admin-data-table>
</template>

<script>
import AdminDataTable from '@/components/utilities/AdminDataTable.vue'
import FichaProveedor from '@/components/inventario/FichaProveedor.vue'

export default {
  name: "Proveedores",
  components: { AdminDataTable, FichaProveedor, },
  data: function() {
    return {
      selectedItem: null,
      readonly: false,
      headers: [
          { value: 'id', text: '#', align: 'center', sortable: false, divider: true, width: 80, },
          { value: 'nombre', text: this.$i18n.t('Nombre'), align: 'left', },
          { value: 'nif', text: this.$i18n.t('NIF'), align: 'left', },
          { value: 'telefono', text: this.$i18n.t('Teléfono'), align: 'center', sortable: false },
          { value: 'email', text: this.$i18n.t('Correo'), align: 'center', },
          { value: 'actions', text: '', align: 'right', sortable: false, },
      ],
    }
  },
}
</script>

<style scoped>
</style>
