import { localStorageAppSettingsField } from '@/config'

export const appSettings = {
    namespaced: true,
    state: {
        locale: 'es',
        receiptPrinterUrl: "https://192.168.192.168/cgi-bin/epos/service.cgi",
    },
    actions: {    
        restoreSettings({ commit }) {
            let previousSettings = localStorage.getItem(localStorageAppSettingsField);
            try {
                commit("update", JSON.parse(previousSettings));
            } catch {
                console.log("Could not parse current App Settings, it will fall back to default.")
            }
        },
    },
    mutations: {
        update(state, newSettings) {
            Object.assign(state, newSettings);
            localStorage.setItem(localStorageAppSettingsField, JSON.stringify(newSettings));
        },
    }
}