<template>
  <v-card :loading="loading">
    <v-card-title class="text-h5 mb-5">{{ this.value?`${$t('Factura')} #${this.value.id}`:$t('Nueva Factura') }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <date-picker v-model="form.fecha" outlined :readonly="readonly" :label="$t('Fecha')" append-icon="mdi-calendar" />
        </v-col>
        <v-col cols="6">
          <buscador-clientes v-model="cliente" outlined :readonly="readonly || !!this.value" />
        </v-col>
      </v-row>
      <v-simple-table v-if="albaranes.length">
        <thead>
          <tr>
            <th class="text-center">{{ $t('Cantidad') }}</th>
            <th class="text-left">{{ $t('Descripción') }}</th>
            <th class="text-right">{{ $t('PVP') }}</th>
            <th class="text-right">{{ $t('Descuento') }}</th>
            <th class="text-right">{{ $t('Importe') }}</th>
          </tr>
        </thead>
        <tbody class="striped">
          <template v-for="albaran, index in albaranes">
            <tr class="albaran-header" :key="`header-${albaran.id}`">
              <th colspan="4">{{ `${$t('Albarán')} #${albaran.id}` }} - {{ albaran.fecha|dateShort }}</th>
              <th class="text-right"><v-btn x-small icon v-if="!readonly" @click="albaranes.splice(index, 1)"><v-icon x-small>mdi-close-circle</v-icon></v-btn></th>
            </tr>
            <tr v-for="linea in albaran.lineas" :key="`linea-${linea.id}`">
              <td class="text-center">{{ linea.cantidad }}</td>
              <td class="text-left">{{ linea.descripcion }}</td>
              <td class="text-right">{{ linea.precio|money }}</td>
              <td class="text-right">{{ linea.descuento|percentage }}</td>
              <td class="text-right">{{ linea|amount|money }}</td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3"></td>
            <td colspan="3">
              <v-text-field class="center-input my-3" :label="$t('Total')" :value="getTotal()|money" outlined readonly />
            </td>
          </tr>
        </tfoot>
      </v-simple-table>
      <v-textarea :label="$t('Observaciones')" v-model="form.observaciones" outlined :readonly="readonly" />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="() => { fillFormFromValue(); $emit('close'); }">{{ $t('Cerrar') }}</v-btn>
      <v-btn color="primary" text v-if="!readonly" @click="save">{{ submitButtonText }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as moment from 'moment'; 
import FichaMixin from '@/components/utilities/mixins/FichaMixin'
import DatePicker from '@/components/utilities/DatePicker.vue'
import BuscadorClientes from '@/components/utilities/BuscadorClientes.vue'

export default {
  name: "FichaFactura",
  mixins: [ FichaMixin ],
  components: { DatePicker, BuscadorClientes },
  data: function() {
    return {
      baseUrl: "admin/ventas/factura/",
      objectName: this.$i18n.t("Factura"),
      cliente: null,
      albaranes: [],
      form: {
        albaranes: [],
        fecha: moment().format("YYYY-MM-DD"),
        cliente: null,
        observaciones: null,
      },
    }
  },
  methods: {
    fillFormFromValue() {
      if (this.value) {
        this.form = {
          albaranes: this.value.albaranes.map((obj) => obj.id),
          cliente: this.value.cliente.id,
          fecha: this.value.fecha,
          observaciones: this.value.observaciones,
        }
        this.cliente = this.value.cliente;
        this.albaranes = this.value.albaranes;
      } else {
        this.form = {
          albaranes: [],
          fecha: moment().format("YYYY-MM-DD"),
          cliente: null,
          observaciones: null,
        }
        this.cliente = null;
        this.albaranes = [];
      }
    },
    getTotal() {
      return this.albaranes.reduce((partial, alb) => partial + this.$options.filters.totalAmount(alb.lineas), 0);
    },
  },
  watch: {
    albaranes() {
      this.form.albaranes = this.albaranes.map((obj) => obj.id);
    },
    cliente() {
      if (this.cliente) {
        this.form.cliente = this.cliente.id;
        if (!this.value) {
          this.albaranes = [];
          this.$apiFetchAll("admin/ventas/albaran/", this.albaranes, { params: { cliente: this.form.cliente, factura__isnull: true }});
        }
      } else {
        this.albaranes = [];
      }
    },
  }
}
</script>

<style scoped>
.striped > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
.albaran-header {
  background-color: beige;
}
.albaran-header > th {
  height: 20px !important;
  font-size: x-small !important;
}
.center-input >>> input {
  text-align: center;
}
</style>
