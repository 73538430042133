<template>
  <v-combobox
    ref="input"
    v-model="localValue"
    @input="(value) => this.$emit('input', value)"
    :label="$t('Cliente')"
    :placeholder="$t('Buscar por nombre o NIF')"
    append-icon="mdi-account"
    :items="choices"
    :loading="loading"
    :search-input.sync="search"
    item-text="nombre"
    hide-no-data
    no-filter
    return-object
    v-bind="$attrs"
  >
    <template #selection="{ item }">
      {{ item.nombre }}
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar color="primary">
        <span class="white--text">{{ getInitials(item.nombre) }}</span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="item.nombre"></v-list-item-title>
        <v-list-item-subtitle class="caption">
          <v-icon small>mdi-card-account-details-outline</v-icon>&nbsp;{{ item.nif|default('N/A') }}&nbsp;-&nbsp;
          <v-icon small>mdi-phone</v-icon>&nbsp;{{ item.telefono|default('N/A') }}&nbsp;-&nbsp;
          <v-icon small>mdi-at</v-icon>&nbsp;{{ item.email|default('N/A') }} 
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        {{ item.pvp|money }}
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: "BuscadorClientes",
  props: ["value"],
  data() {
    return {
      choices: this.value?[this.value]:[],
      loading: false,
      localValue: this.value,
      search: null,
    }
  },
  methods: {
    getInitials(name) {
      let words = name.split(" ").filter((obj) => obj.indexOf("(") == -1);
      return words.map((obj) => obj[0]).join("")
    }
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
    search() {
        if (!this.search) return
        this.loading = true
        var thisCallTime = this.lastFetchTime = Date.now()
        this.$apiRequest('get', 'admin/inventario/cliente/', { params: { search: this.search } })
        .then(res => {
          if (this.lastFetchTime <= thisCallTime) {
            const { results } = res.data;
            this.choices = results;
          }
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped></style>
