import axios from 'axios';

function printReceipt(printerUrl, xml, timeout=10000) {
    return axios({
        method: 'post',
        url: printerUrl,
        params: {"devid": "local_printer", "timeout": timeout},
        headers: {"Content-Type": "text/xml; charset=utf-8", "SOAPAction": '""', "Accept": 'application/xml' },
        data: `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body>${xml}</s:Body></s:Envelope>`,
        timeout: timeout,
      }).then((res) => {
        var parser = new DOMParser();
        var response = parser.parseFromString(res.data, "text/xml").getElementsByTagName("response")[0];
        if (response.getAttribute("success") == "false") {
          throw response.getAttribute("code")
        }
      })
}

export {
    printReceipt
}
