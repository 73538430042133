<template>
  <v-fade-transition appear>
    <v-badge overlap color="primary darken-4" bottom bordered offset-x="25" :content="shortcut" v-if="shortcut">
      <v-btn large color="primary" dark class="mx-1" @click="submit">{{ $t("Línea libre") }}</v-btn>
    </v-badge>
    <v-btn large color="primary" dark class="mx-1" @click="submit" v-else>{{ $t("Línea libre") }}</v-btn>
  </v-fade-transition>
</template>

<script>
export default {
  name: "NuevaLineaLibre",
  props: ["shortcut"],
  data: function() {
    return {
      emptyLine: {
        id: null,
        descripcion: this.$i18n.t("OTROS"),
        pvp: 0,
      },
    }
  },
  methods: {
    submit() {
      this.$emit("input", this.emptyLine);
    },
  },
}
</script>

<style scoped></style>
