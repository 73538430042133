<template>
  <v-data-table v-bind="$attrs" :items="lineas" :headers="headers" class="striped"
    disable-sort disable-pagination disable-filtering hide-default-footer
  >
    <template #top>
      <div class="d-flex align-center" v-if="!readonly">
        <buscador-productos ref="search" @input="addProducto" />
      </div>
      <actualizar-pvp ref="actualizarPvp" @done="actualizarPvpExistentes" />
    </template>
    <template #foot v-if="showTotal">
      <td colspan="4">
        <slot name="foot-space" />
      </td>
      <td colspan="3">
        <v-text-field class="center-input my-3" :label="$t('Total')" :value="lineas|totalAmount|money" outlined readonly />
      </td>
    </template>
    <template #[`item.cantidad`]="{ item }">
      <v-edit-dialog v-if="!readonly">
        {{ item.cantidad }}
        <template v-slot:input>
          <v-text-field v-model="item.cantidad" type="number" />
        </template>
      </v-edit-dialog>
      <span v-else>{{ item.cantidad }}</span>
    </template>
    <template #[`item.descripcion`]="{ item }">
      <v-edit-dialog v-if="!readonly">
        {{ item.descripcion }}
        <template v-slot:input>
          <v-text-field v-model="item.descripcion" />
        </template>
      </v-edit-dialog>
      <span v-else>{{ item.descripcion }}</span>
    </template>
    <template #[`item.pvp`]="{ item }">
      <span class="editable-pvp" @click="actualizarPvp(item)">{{ item.producto.pvp|money }}</span>&nbsp;
    </template>
    <template #[`item.precio`]="{ item }">
      <v-edit-dialog v-if="!readonly">
        {{ item.precio|money }}
        <template v-slot:input>
          <v-text-field v-model="item.precio" type="number" step="0.01" suffix="€" />
        </template>
      </v-edit-dialog>
      <span v-else>{{ item.precio|money }}</span>
    </template>
    <template #[`item.descuento`]="{ item }">
      <v-edit-dialog v-if="!readonly">
        {{ item.descuento|percentage }}
        <template v-slot:input>
          <v-text-field v-model="item.descuento" type="number" suffix="%" />
        </template>
      </v-edit-dialog>
      <span v-else>{{ item.descuento|percentage }}</span>
    </template>
    <template #[`item.importe`]="{ item }">
      <v-edit-dialog v-if="!readonly">
        {{ getImporte(item)|money }}
        <template v-slot:input>
          <v-text-field :value="getImporte(item)" @input="(value) => setImporte(item, value)" type="number" step=0.1 suffix="€" />
        </template>
      </v-edit-dialog>
      <span v-else>{{ getImporte(item)|money }}</span>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-icon small v-if="!readonly" @click.stop="lineas.splice(lineas.indexOf(item), 1)">mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import BuscadorProductos from "@/components/utilities/BuscadorProductos"
import ActualizarPvp from '@/components/compras/ActualizarPvp'

export default {
  name: "TablaLineas",
  components: { BuscadorProductos, ActualizarPvp },
  props: {
    value: {
      type: Array,
      default() { return [] },
    },
    readonly: {
      type: Boolean,
      default: false,   
    },
    showTotal: {
      type: Boolean,
      default: true,   
    },
  },
  data: function() {
    return {
      lineas: [],
      headers: [
          { value: 'cantidad', text: this.$i18n.t('Cantidad'), align: 'center', },
          { value: 'descripcion', text: this.$i18n.t('Descripción'), align: 'left', },
          { value: 'pvp', text: this.$i18n.t('PVP'), align: 'right', },
          { value: 'precio', text: this.$i18n.t('Coste'), align: 'right', },
          { value: 'descuento', text: this.$i18n.t('Descuento'), align: 'right' },
          { value: 'importe', text: this.$i18n.t('Importe'), align: 'right', },
          { value: 'actions', text: '', align: 'right', },
      ],
    }
  },
  methods: {
    addProducto(producto, checkExistent=true) {
      if ((checkExistent) && (this.lineas.map((obj) => obj.producto.id).indexOf(producto.id) > -1)) {
        this.lineas.find((obj) => obj.producto.id == producto.id).cantidad += 1;
      } else {
        this.lineas.push({
          producto: producto,
          descripcion: producto.descripcion,
          cantidad: 1,
          precio: producto.pvp,
          descuento: 0,
        });
      }
    },
    getImporte(linea) {
      return this.$options.filters.round((linea.precio * (1-linea.descuento/100)) * linea.cantidad);
    },
    setImporte(linea, newValue) {
      linea.descuento = this.$options.filters.round((1 - (newValue/linea.cantidad)/linea.precio) * 100); 
    },
    actualizarPvp(item) {
      this.$refs.actualizarPvp.idProducto = item.producto.id;
      this.$refs.actualizarPvp.pvpActual = item.producto.pvp;
      this.$refs.actualizarPvp.coste = item.precio;
      this.$refs.actualizarPvp.dialog = true;
    },
    actualizarPvpExistentes(item) {
      this.lineas.forEach((linea) => {
        if (linea.producto.id == item.id) {
          linea.producto.pvp = item.pvp;
        }
      });
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() { this.lineas = this.value; },
    },
    lineas: {
      deep: true,
      handler() { this.$emit('input', this.lineas); },
    }
  },
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
.striped >>> .v-data-table__empty-wrapper {
  display: none;
}
.center-input >>> input {
  text-align: center;
}
.editable-pvp {
  cursor: pointer;
}
</style>
