<template>
  <v-row>
    <v-col cols="6">
      <v-menu v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition" offset-y min-width="auto">
        <template #activator="{ on }">
          <v-text-field v-model="date" readonly v-bind="$attrs" v-on="on" :label="$t('Fecha')" append-icon="mdi-calendar"/>
        </template>
        <v-date-picker v-if="!readonly" no-title v-model="date" @input="dateMenu = false" :locale="$i18n.locale" :readonly="readonly" />
      </v-menu>
    </v-col>
    <v-col cols="6">
      <v-menu v-model="timeMenu" ref="timeMenu"
        :close-on-content-click="false" :return-value.sync="time"
        transition="scale-transition" offset-y min-width="auto">
        <template #activator="{ on }">
          <v-text-field v-model="time" readonly v-bind="$attrs" v-on="on" :label="$t('Hora')" append-icon="mdi-clock-outline" />
        </template>
        <v-time-picker v-if="!readonly" v-model="time" @click:minute="$refs.timeMenu.save(time)" scrollable :locale="$i18n.locale" format="24hr" />
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
  import * as moment from 'moment'; 

  export default {
    props: ["value", "readonly", ],
    data() {
      return {
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm"),
        dateMenu: false,
        timeMenu: false,
      }
    },
    computed: {
      datetime() {
        return this.date + " " + this.time;
      }
    },
    watch: {
      value: {
        immediate: true,
        handler() {
          if (this.value) {
            [this.date, this.time] = this.value.split(" ");
          }
        }
      },
      datetime() {
        this.$emit("input", this.datetime);
      },
    }
  }
</script>

<style scoped>
</style>