<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card :loading="loading">
      <v-card-title class="text-h5 mb-5">{{ title }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="9">
            <v-text-field :label="$t('Nombre')" v-model="form.nombre" outlined append-icon="mdi-account-outline" autofocus :readonly="readonly" />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="$t('NIF')" v-model="form.nif" outlined append-icon="mdi-card-account-details-outline" :readonly="readonly" />
          </v-col>
        </v-row>
        <v-text-field :label="$t('Teléfono')" v-model="form.telefono" outlined append-icon="mdi-phone" :readonly="readonly" />
        <v-text-field :label="$t('Correo electrónico')" v-model="form.email" outlined append-icon="mdi-at" :readonly="readonly" />
        <v-row>
          <v-col cols="6">
          </v-col>
          <v-col cols="6">
          </v-col>
        </v-row>
        <v-textarea :label="$t('Observaciones')" v-model="form.observaciones" outlined :readonly="readonly" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="$emit('close')">{{ $t('Cerrar') }}</v-btn>
        <v-btn color="primary" text v-if="!readonly" @click="save">{{ submitButtonText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FichaMixin from '@/components/utilities/mixins/FichaMixin'

export default {
  name: "FichaProveedor",
  mixins: [ FichaMixin, ],
  data: function() {
    return {
      baseUrl: "admin/inventario/proveedor/",
      objectName: this.$i18n.t("Proveedor"),
      form: {
        nombre: null,
        nif: null,
        telefono: null,
        email: null,
        observaciones: null,
      },
    }
  },
}
</script>

<style scoped>
</style>
