<template>
  <v-card :loading="loading">
    <v-card-title class="text-h5 mb-5">{{ title }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <date-picker v-model="form.fecha" outlined :readonly="readonly" :label="$t('Fecha')" append-icon="mdi-calendar" />
        </v-col>
        <v-col cols="6">
          <buscador-clientes v-model="cliente" outlined :readonly="readonly" />
        </v-col>
      </v-row>
      <tabla-lineas v-model="form.lineas" :readonly="readonly"/>
      <v-textarea :label="$t('Observaciones')" v-model="form.observaciones" outlined :readonly="readonly" />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="$emit('close')">{{ $t('Cerrar') }}</v-btn>
      <v-btn color="primary" text v-if="!readonly" @click="save">{{ submitButtonText }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as moment from 'moment'; 
import FichaMixin from '@/components/utilities/mixins/FichaMixin'
import TablaLineas from "@/components/utilities/TablaLineas"
import DatePicker from '@/components/utilities/DatePicker.vue'
import BuscadorClientes from '@/components/utilities/BuscadorClientes.vue'

export default {
  name: "FichaAlbaran",
  mixins: [ FichaMixin ],
  components: { TablaLineas, DatePicker, BuscadorClientes },
  data: function() {
    return {
      baseUrl: "admin/ventas/albaran/",
      objectName: this.$i18n.t("Albarán"),
      cliente: null,
      form: {
        lineas: [],
        fecha: moment().format("YYYY-MM-DD"),
        cliente: null,
        observaciones: null,
      },
    }
  },
  methods: {
    fillFormFromValue() {
      if (this.value) {
        this.form = {
          lineas: this.value.lineas,
          cliente: null,
          fecha: this.value.fecha,
          observaciones: this.value.observaciones,
        }
        this.cliente = this.value.cliente;
      } else {
        this.form = {
          lineas: [],
          fecha: moment().format("YYYY-MM-DD"),
          cliente: null,
          observaciones: null,
        }
        this.cliente = null;
      }
    }
  },
  watch: {
    cliente() {
      if (this.cliente) {
        this.form.cliente = this.cliente.id;
      }
    }
  }
}
</script>

<style scoped>
</style>
