var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({staticClass:"striped",attrs:{"items":_vm.lineas,"headers":_vm.headers,"disable-sort":"","disable-pagination":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(!_vm.readonly)?_c('div',{staticClass:"d-flex align-center"},[_c('buscador-productos',{ref:"search",on:{"input":_vm.addProducto}})],1):_vm._e(),_c('actualizar-pvp',{ref:"actualizarPvp",on:{"done":_vm.actualizarPvpExistentes}})]},proxy:true},(_vm.showTotal)?{key:"foot",fn:function(){return [_c('td',{attrs:{"colspan":"4"}},[_vm._t("foot-space")],2),_c('td',{attrs:{"colspan":"3"}},[_c('v-text-field',{staticClass:"center-input my-3",attrs:{"label":_vm.$t('Total'),"value":_vm._f("money")(_vm._f("totalAmount")(_vm.lineas)),"outlined":"","readonly":""}})],1)]},proxy:true}:null,{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [(!_vm.readonly)?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number"},model:{value:(item.cantidad),callback:function ($$v) {_vm.$set(item, "cantidad", $$v)},expression:"item.cantidad"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.cantidad)+" ")]):_c('span',[_vm._v(_vm._s(item.cantidad))])]}},{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [(!_vm.readonly)?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{model:{value:(item.descripcion),callback:function ($$v) {_vm.$set(item, "descripcion", $$v)},expression:"item.descripcion"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.descripcion)+" ")]):_c('span',[_vm._v(_vm._s(item.descripcion))])]}},{key:"item.pvp",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"editable-pvp",on:{"click":function($event){return _vm.actualizarPvp(item)}}},[_vm._v(_vm._s(_vm._f("money")(item.producto.pvp)))]),_vm._v("  ")]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [(!_vm.readonly)?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","step":"0.01","suffix":"€"},model:{value:(item.precio),callback:function ($$v) {_vm.$set(item, "precio", $$v)},expression:"item.precio"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("money")(item.precio))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("money")(item.precio)))])]}},{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [(!_vm.readonly)?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","suffix":"%"},model:{value:(item.descuento),callback:function ($$v) {_vm.$set(item, "descuento", $$v)},expression:"item.descuento"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.descuento))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("percentage")(item.descuento)))])]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [(!_vm.readonly)?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"value":_vm.getImporte(item),"type":"number","step":"0.1","suffix":"€"},on:{"input":function (value) { return _vm.setImporte(item, value); }}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.getImporte(item)))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("money")(_vm.getImporte(item))))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.readonly)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();_vm.lineas.splice(_vm.lineas.indexOf(item), 1)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }