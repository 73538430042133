var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('admin-data-table',{ref:"dataTable",attrs:{"fetch-url":"admin/ventas/albaran/","headers":_vm.headers,"get-stats":true,"filters":{ fecha__date__gte: this.dateFrom, fecha__date__lte: this.dateTo, factura__isnull: this.filtroNoFacturados },"editable":function (item) { return !item.factura; },"deletable":function (item) { return !item.factura; }},on:{"create":function($event){_vm.readonly=false; _vm.selectedItem=null; _vm.formDialog=true},"read":function (item) {_vm.readonly=true; _vm.selectedItem=item; _vm.formDialog=true;},"update":function (item) {_vm.readonly=false; _vm.selectedItem=item; _vm.formDialog=true;},"stats":function (value) { return _vm.stats = value; }},scopedSlots:_vm._u([{key:"extra-filters",fn:function(){return [_c('v-select',{attrs:{"label":_vm.$t('Estado'),"items":_vm.filtroNoFacturadosOptions,"append-icon":"mdi-receipt","filled":"","dense":"","hide-details":""},model:{value:(_vm.filtroNoFacturados),callback:function ($$v) {_vm.filtroNoFacturados=$$v},expression:"filtroNoFacturados"}}),_c('date-picker',{attrs:{"label":_vm.$t('Fecha desde'),"dense":"","filled":"","append-icon":"mdi-calendar","hide-details":"","clearable":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('date-picker',{attrs:{"label":_vm.$t('Fecha hasta'),"dense":"","filled":"","append-icon":"mdi-calendar","hide-details":"","clearable":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})]},proxy:true},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-italic font-weight-light"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm._f("dateLong")(item.fecha))))])]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("money")(_vm._f("totalAmount")(item.lineas))))]}},{key:"item.cliente__nombre",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.cliente.nombre))]}},{key:"item.factura",fn:function(ref){
var item = ref.item;
return [(item.factura)?_c('span',[_vm._v(_vm._s(_vm.$t('Factura'))+" #"+_vm._s(item.factura.id))]):_vm._e()]}},{key:"dialogs",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[_c('ficha-albaran',{attrs:{"readonly":_vm.readonly,"value":_vm.selectedItem},on:{"close":function($event){_vm.formDialog=false},"done":function () { _vm.$refs.dataTable.fetch() }}})],1)]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-h6 secondary--text"},[_vm._v(_vm._s(_vm.stats.count)+" albaranes")]),_c('div',{staticClass:"text-h4 primary--text"},[_vm._v(_vm._s(_vm._f("money")(_vm.stats.total)))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }