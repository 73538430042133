<template>
  <admin-data-table fetch-url="admin/compras/albaran/" :headers="headers" ref="dataTable"
    :filters="{ fecha__date__gte: this.dateFrom, fecha__date__lte: this.dateTo }"
    @create="readonly=false; selectedItem=null; formDialog=true"
    @read="(item) => {readonly=true; selectedItem=item; formDialog=true;}"
    @update="(item) => {readonly=false; selectedItem=item; formDialog=true;}"
    >
    <template #extra-filters>
      <date-picker v-model="dateFrom" :label="$t('Fecha desde')" dense filled  append-icon="mdi-calendar" hide-details clearable />
      <date-picker v-model="dateTo" :label="$t('Fecha hasta')" dense filled append-icon="mdi-calendar" hide-details clearable />
    </template>
    <template #[`item.fecha`]="{ item }">
      <span class="font-italic font-weight-light">{{ item.fecha|dateLong }}</span>
    </template>
    <template #[`item.importe`]="{ item }">{{ item.lineas|totalAmount|money }}</template>
    <template #[`item.proveedor__nombre`]="{ item }">{{ item.proveedor.nombre }}</template>
    <template #dialogs>
      <v-dialog v-model="formDialog" max-width="800">
        <ficha-albaran :readonly="readonly" :value="selectedItem" @close="formDialog=false" @done="() => { $refs.dataTable.fetch() }" />
      </v-dialog>
    </template>
  </admin-data-table>
</template>

<script>
import AdminDataTable from '@/components/utilities/AdminDataTable.vue'
import DatePicker from '@/components/utilities/DatePicker.vue'
import FichaAlbaran from '@/components/compras/FichaAlbaran.vue'

export default {
  name: "Albaranes",
  components: { AdminDataTable, DatePicker, FichaAlbaran },
  data: function() {
    return {
      dateFrom: null,
      dateTo: null,
      selectedItem: null,
      formDialog: false,
      readonly: true,
      headers: [
          { value: 'id', text: '#', align: 'center', sortable: false, divider: true, width: 80, },
          { value: 'proveedor__nombre', text: this.$i18n.t('Proveedor'), align: 'left', sortable: true },
          { value: 'fecha', text: this.$i18n.t('Fecha'), align: 'left', },
          { value: 'referencia', text: this.$i18n.t('Referencia'), align: 'center', sortable: false },
          { value: 'importe', text: this.$i18n.t('Importe'), align: 'right', sortable: false },
          { value: 'actions', text: '', align: 'right', sortable: false, },
      ],
    }
  },
}
</script>

<style scoped>
</style>
