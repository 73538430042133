<template>
  <v-container fluid fill-width class="justify-center">
    <v-tabs v-model="tab" align-with-title>
      <v-tab key="tickets">{{ $t('Tickets') }}</v-tab>
      <v-tab key="albaranes">{{ $t('Albaranes') }}</v-tab>
      <v-tab key="facturas">{{ $t('Facturas') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="tickets">
        <v-card flat>
          <v-card-text><tickets ref="tickets" /></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="albaranes">
        <v-card flat>
          <v-card-text><albaranes ref="albaranes" /></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="facturas">
        <v-card flat>
          <v-card-text><facturas ref="facturas" /></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Tickets from '@/components/ventas/tabs/Tickets.vue'
import Albaranes from '@/components/ventas/tabs/Albaranes.vue'
import Facturas from '@/components/ventas/tabs/Facturas.vue'

export default {
  name: 'Ventas',
  components: { Tickets, Albaranes, Facturas },
  data: () => {
    return {
      tab: null,
    }
  },
  watch: {
    tab() {
      if (this.$refs.albaranes && (this.tab == 1)) {
        this.$refs.albaranes.$refs.dataTable.fetch();
      }
    }
  }
}
</script>

<style scoped>
</style>
