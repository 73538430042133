<template>
    <nav v-if="$store.getters['auth/loggedIn']">
        <v-app-bar flat app>
            <v-img alt="Colors" class="shrink mr-2" contain
                src="@/assets/img/logo.png" transition="scale-transition" width="120" />
            <v-toolbar-items>
                <v-btn text class="text--secondary" v-for="link in links" :key="link.text"
                    :disabled="$route.name == link.route.name" @click="$router.push(link.route)">
                    <v-icon class="mx-2">{{ link.icon }}</v-icon>{{ link.text }}
                </v-btn>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <app-settings />
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </v-toolbar-items>
        </v-app-bar> 
        
        <v-navigation-drawer app dark color="primary" v-model="drawer">
            <v-toolbar flat color="primary lighten-1">
                <clock />
            </v-toolbar>
            <v-divider></v-divider>
            <v-list>
                <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
                    <v-list-item-action>
                        <v-icon>{{ link.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ link.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout">
                    <v-list-item-action>
                        <v-icon>mdi-exit-to-app</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("Cerrar sesión") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </nav>
</template>


<script>
import AppSettings from '@/components/base/AppSettings'
import Clock from '@/components/base/Clock'

export default {
    data() {
        return {
            drawer: false,
        }
    },
    components: { Clock, AppSettings },
    methods: {
        logout () {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push("login");
            });
        }
    },
    computed: {
        links() {
            return [
                {icon: 'mdi-home', text: this.$t('Home'), route: '/' },
                {icon: 'mdi-cash-register', text: this.$t('TPV'), route: '/tpv' },
                {icon: 'mdi-cart', text: this.$t('Compras'), route: '/compras' },
                {icon: 'mdi-cash-multiple', text: this.$t('Ventas'), route: '/ventas' },
                {icon: 'mdi-store', text: this.$t('Inventario'), route: '/inventario' },
                {icon: 'mdi-cogs', text: this.$t('Admin'), route: '/admin'},
            ]
        },
    },
}
</script>
