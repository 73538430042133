var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',_vm._b({staticClass:"striped",attrs:{"items":_vm.lineas,"headers":_vm.headers,"disable-sort":"","disable-pagination":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(!_vm.readonly)?_c('div',{staticClass:"d-flex align-center"},[_c('buscador-productos',{ref:"search",on:{"input":_vm.addProducto}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","tile":"","text":"","color":"primary"},on:{"click":function($event){return _vm.addProducto(_vm.empty, _vm.checkExistent=false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-gesture")])],1)]}}],null,false,348146615)},[_c('span',[_vm._v(_vm._s(_vm.$t("Nueva línea libre")))])])],1):_vm._e()]},proxy:true},(_vm.showTotal)?{key:"foot",fn:function(){return [_c('td',{attrs:{"colspan":"3"}},[_vm._t("foot-space")],2),_c('td',{attrs:{"colspan":"3"}},[_c('v-text-field',{staticClass:"center-input my-3",attrs:{"label":_vm.$t('Total'),"value":_vm._f("money")(_vm._f("totalAmount")(_vm.lineas)),"outlined":"","readonly":""}})],1)]},proxy:true}:null,{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [(!_vm.readonly)?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"value":item.cantidad,"type":"number"},on:{"input":function (value) { return _vm.setCantidad(item, value); }}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.cantidad)+" ")]):_c('span',[_vm._v(_vm._s(item.cantidad))])]}},{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [(!_vm.readonly)?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{model:{value:(item.descripcion),callback:function ($$v) {_vm.$set(item, "descripcion", $$v)},expression:"item.descripcion"}})]},proxy:true}],null,true)},[(_vm.isPromo(item))?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"success","x-small":""}},[_vm._v("PROMO")]):_vm._e(),_c('span',{staticClass:"mx-5"},[_vm._v(_vm._s(item.descripcion))]),(!_vm.readonly)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();_vm.editProduct = item.producto; _vm.$refs.fichaProducto.dialog = true}}},[_vm._v("mdi-pencil")]):_vm._e()],1):_c('span',[_vm._v(_vm._s(item.descripcion))])]}},{key:"item.precio",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(!_vm.readonly)?_c('v-edit-dialog',{on:{"save":function($event){_vm.$set(_vm.importes, index, _vm.getImporte(item))}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","step":"0.01","suffix":"€"},model:{value:(item.precio),callback:function ($$v) {_vm.$set(item, "precio", $$v)},expression:"item.precio"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("money")(item.precio))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("money")(item.precio)))])]}},{key:"item.descuento",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(!_vm.readonly)?_c('v-edit-dialog',{on:{"save":function($event){_vm.$set(_vm.importes, index, _vm.getImporte(item))}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","suffix":"%"},model:{value:(item.descuento),callback:function ($$v) {_vm.$set(item, "descuento", $$v)},expression:"item.descuento"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("percentage")(item.descuento))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("percentage")(item.descuento)))])]}},{key:"item.importe",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(!_vm.readonly)?_c('v-edit-dialog',{on:{"save":function($event){return _vm.setImporte(item, _vm.importes[index])}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","step":"0.1","suffix":"€"},model:{value:(_vm.importes[index]),callback:function ($$v) {_vm.$set(_vm.importes, index, $$v)},expression:"importes[index]"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("money")(_vm.importes[index]))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("money")(_vm.importes[index])))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.readonly)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();_vm.lineas.splice(_vm.lineas.indexOf(item), 1)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)},'v-data-table',_vm.$attrs,false)),_c('ficha-producto',{ref:"fichaProducto",attrs:{"value":_vm.editProduct},on:{"done":function($event){_vm.editProduct = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }