<template>
  <v-container fluid fill-width class="justify-center">
    <v-tabs v-model="tab" align-with-title>
      <v-tab key="albaranes">{{ $t('Albaranes') }}</v-tab>
      <v-tab key="devoluciones">{{ $t('Devoluciones') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="albaranes">
        <v-card flat>
          <v-card-text><albaranes /></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="devoluciones">
        <v-card flat>
          <v-card-text></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Albaranes from '@/components/compras/tabs/Albaranes.vue'

export default {
  name: 'Compras',
  components: { Albaranes, },
  data: () => {
    return {
      tab: null,
    }
  },
}
</script>

<style scoped>
</style>
