
<template>
  <v-alert
    border="bottom"
    colored-border
    color="primary"
    elevation="2">
    <div class="primary--text mb-2 font-weight-bold text-overline">Frase del día</div>
    <div v-if="!loading">
      <div class="font-italic">{{ frase }}</div>
      <div class="font-weight-bold text-right mt-4">{{ autor }}</div>
    </div>
    <div class="text-center my-5" v-else>
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "FraseDelDia",
  data() {
    return {
      loading: true,
      frase: null,
      autor: null,
    }
  },
  mounted() {
    this.$apiRequest('get', 'get-cita/').then(res => {
      this.frase = res.data.frase;
      this.autor = res.data.autor;
      this.loading = false;
    })
  },
}
</script>

<style scoped>
</style>