<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card :loading="loading">
      <v-card-title class="text-h5 mb-5">{{ title }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="9">
            <v-text-field :label="$t('Código')" v-model="form.codigo" outlined append-icon="mdi-barcode" autofocus :readonly="readonly" />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="$t('PVP')" v-model="form.pvp" outlined type="number" append-icon="mdi-currency-eur" :readonly="readonly" />
          </v-col>
        </v-row>
        <v-text-field :label="$t('Descripción')" v-model="form.descripcion" outlined append-icon="mdi-clipboard-text" :readonly="readonly" />
        <v-row>
          <v-col cols="6">
            <v-autocomplete :label="$t('Familia')" v-model="form.familia" :items="familiaOptions" item-text="nombre" item-value="id" outlined append-icon="mdi-tag" :readonly="readonly">
              <template #selection="{ item }">
                <v-chip small dark :color="item.color">{{ item.nombre }}</v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-select :label="$t('Tipo IVA')" v-model="form.tipo_iva" :items="tipoIvaOptions" item-text="nombre" item-value="id" outlined append-icon="mdi-bank-outline" :readonly="readonly">
              <template #selection="{ item }">
                {{ item.nombre }} ({{ item.tasa }} %)
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-textarea :label="$t('Observaciones')" v-model="form.observaciones" outlined :readonly="readonly" />
        <div v-if="form.promociones.length">
          <v-alert class="px-0 pt-1" border="bottom" colored-border color="primary" elevation="2">
            <div class="text-h6 text-overline primary--text ml-5">Promociones</div>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-center">Cantidad >=</th>
                  <th class="text-center">Descuento (%)</th>
                  <th class="text-center">Precio final</th>
                </tr>
              </thead>
              <v-slide-y-transition group tag="tbody">
                <tr v-for="line, j in form.promociones" :key="j">
                  <td class="text-center">
                    <v-edit-dialog v-if="!readonly">
                      {{ line.cantidad_desde }}
                      <template v-slot:input>
                        <v-text-field v-model="line.cantidad_desde" type="number" />
                      </template>
                    </v-edit-dialog>
                    <span v-else>{{ line.cantidad_desde }}</span>
                  </td>
                  <td class="text-center">
                    <v-edit-dialog v-if="!readonly" @save="$set(preciosFinalesPromociones, j, getPromoPrecio(line))">
                      {{ line.descuento|percentage }}
                      <template v-slot:input>
                        <v-text-field v-model="line.descuento" type="number" suffix="%" />
                      </template>
                    </v-edit-dialog>
                    <span v-else>{{ line.descuento|percentage }}</span>
                  </td>
                  <td class="text-center">
                    <v-edit-dialog v-if="!readonly" @save="setPromoPrecio(line, preciosFinalesPromociones[j])">
                      {{ preciosFinalesPromociones[j]|money }}
                      <template v-slot:input>
                        <v-text-field v-model="preciosFinalesPromociones[j]" step="0.01" suffix="€" />
                      </template>
                    </v-edit-dialog>
                    <span v-else>{{ preciosFinalesPromociones[j]|money }}</span>
                  </td>
                </tr>
              </v-slide-y-transition>
            </v-simple-table>
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="dialog = false">{{ $t('Cerrar') }}</v-btn>
        <v-btn color="secondary" text v-if="!readonly" @click="addPromocion()">Añadir Promoción</v-btn>
        <v-btn color="primary" text v-if="!readonly" @click="save">{{ submitButtonText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FichaMixin from '@/components/utilities/mixins/FichaMixin'

export default {
  name: "FichaProducto",
  mixins: [ FichaMixin, ],
  data: function() {
    return {
      baseUrl: "admin/inventario/producto/",
      objectName: this.$i18n.t("Artículo"),
      form: {
        codigo: null,
        pvp: null,
        descripcion: null,
        familia: null,
        tipo_iva: null,
        observaciones: null,
        promociones: [],
      },
      preciosFinalesPromociones: [],
      familiaOptions: [],
      tipoIvaOptions: [],
    }
  },
  mounted() {
    this.$apiFetchAll("admin/inventario/familia/", this.familiaOptions);
    this.$apiFetchAll("admin/inventario/tipoiva/", this.tipoIvaOptions);
  },
  methods: {
    fillFormFromValue() {
      if (this.value) {
        this.form = {
          codigo: this.value.codigo,
          pvp: this.value.pvp,
          descripcion: this.value.descripcion,
          familia: this.value.familia?this.value.familia.id:null,
          tipo_iva: this.value.tipo_iva?this.value.tipo_iva.id:null,
          observaciones: this.value.observaciones,
          promociones: this.value.promociones,
        }
        this.preciosFinalesPromociones = this.value.promociones.map(this.getPromoPrecio);
      }
    },
    addPromocion() {
      let nuevaPromo = {
        cantidad_desde: 1,
        descuento: 10,
      };
      this.form.promociones.push(nuevaPromo);
      this.preciosFinalesPromociones.push(this.getPromoPrecio(nuevaPromo));
    },
    getPromoPrecio(promocion) {
      console.log(promocion.descuento);
      return (this.form.pvp * (100 - promocion.descuento) / 100).toFixed(2);
    },
    setPromoPrecio(promo, precio) {
      precio = parseFloat(precio) || 0;
      promo.descuento = ((1 - precio / this.form.pvp) * 100).toFixed(2);
    },
    debug(e) {
      console.log(e)
    }
  },
  watch: {
    "form.pvp": function() {
      this.preciosFinalesPromociones = this.value.promociones.map(this.getPromoPrecio);
    }
  }
}
</script>

<style scoped>
</style>
