<template>
  <v-container fluid fill-width class="pa-0">
    <v-row no-gutters>
      <v-col cols="9">
        <div class="ma-3">
          <apartados @input="cargarApartado" />
        </div>
        <div ref="tableContainer" v-resize="onResize" class="mx-2" >
          <tabla-lineas ref="tablaLineas" v-model="lines" :height="tableHeight-64" :show-total="false" />
        </div>
      </v-col>
      <v-col cols="3" class="pa-5">
        <frase-del-dia />
      </v-col>
    </v-row>
    <v-footer padless class="d-flex align-center justify-space-between" :height="footerHeight">
      <div class="d-flex align-center flex-row-reverse flex-grow-1 px-5">
        <nueva-linea-libre @input="(line) => $refs.tablaLineas.addProducto(line, checkExistent=false)" /> 
        <cerrar-ticket :sales="lines" v-if="lines.length > 0" @done="resetTpv()" />
        <apartar :sales="lines" :defaults="{cliente: cliente, deposito: yaPagado}" v-if="lines.length > 0" @done="resetTpv()" />
        <crear-albaran :sales="lines" :defaults="{cliente: cliente}" v-if="lines.length > 0" @done="resetTpv()" />
        <limpiar-tpv v-if="lines.length > 0" @done="resetTpv()" />
        <v-btn v-shortkey.once="['f1']" @shortkey="focusBarcodeReader()" v-show="false"></v-btn>
      </div>
      <div class="d-flex justify-center align-center total-box">
        <span class="total-box-label">{{ $t("Total") }}</span>
        <span class="text-h4 font-weight-bold">{{ this.lines|totalAmount|money }}</span>
      </div>
    </v-footer>
  </v-container>
</template>

<script>
import TablaLineas from "@/components/utilities/TablaLineas"
import CerrarTicket from "@/components/tpv/actions/CerrarTicket"
import LimpiarTpv from '@/components/tpv/actions/LimpiarTpv.vue'
import Apartar from '@/components/tpv/actions/Apartar.vue'
import CrearAlbaran from '@/components/tpv/actions/CrearAlbaran.vue'
import NuevaLineaLibre from '@/components/tpv/actions/NuevaLineaLibre.vue'
import Apartados from '@/components/tpv/submenus/Apartados.vue'
import FraseDelDia from "@/components/utilities/FraseDelDia.vue"

export default {
  name: 'TPV',
  components: {
    TablaLineas,
    Apartados,
    NuevaLineaLibre, CerrarTicket, LimpiarTpv, Apartar, CrearAlbaran,
    FraseDelDia,
  },
  data: () => {
    return {
      lines: [],
      tableHeight: 0,
      footerHeight: 100,
      cliente: null,
      yaPagado: 0,
    }
  },
  mounted() {
    this.focusBarcodeReader();
  },
  methods: {
    onResize() {
      this.$nextTick(() => {
        this.tableHeight = window.innerHeight - this.$refs.tableContainer.getBoundingClientRect().y - this.footerHeight;
      })
    },
    cargarApartado(apartado) {
      this.lines = this.lines.concat(apartado.lineas);
      this.cliente = apartado.cliente;
      this.yaPagado = apartado.deposito || 0;
    },
    focusBarcodeReader() {
      let searchInput = this.$refs.tablaLineas.$refs.search.$refs.barcodeInput.$refs.input;
      if (document.activeElement === searchInput) {
        searchInput.blur();
      } else {
        searchInput.focus();
      }
    },
    resetTpv() {
      this.lines = [];
      this.$nextTick(this.focusBarcodeReader);
      this.yaPagado = 0;
      this.cliente = null;
    },
  },
};
</script>

<style scoped>
.tpv-footer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: solid thin gray;
}
.total-box {
  height: 100%;
  width: 20%;
  padding: 50px;
  position: relative;
  border-left: solid thin gray;
}
.total-box-label {
  position: absolute;
  left: 0;
  top: 0;
  padding: 5px 15px;
  color: gray;
}
.actions {
  padding: 0 20px;
}
</style>
