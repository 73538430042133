
export default {
  name: "FichaMixin",
  props: {
    value: {
      type: Object,
      default() { return {} }
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data: function() {
    return {
      dialog: false,
      loading: false,
      baseUrl: "admin/",
      form: {},
      objectName: "",
    }
  },
  computed: {
    mode(){
      if (this.value && this.readonly) {
        return "read"
      } else if (this.value) {
        return "update"
      } else {
        return "create"
      }
    },
    submitButtonText() {
      return {
        "create": this.$i18n.t("Crear"),
        "read": this.$i18n.t("Ok"),
        "update": this.$i18n.t("Guardar"),
      }[this.mode]
    },
    title() {
      return {
        "create": `${this.$i18n.t("Nuevo")} ${this.objectName}`,
        "read": this.value?`${this.objectName} #${this.value.id}`:'',
        "update": `${this.$i18n.t("Modificar")} ${this.objectName}`,
      }[this.mode]
    },
  },
  methods: {
    save() {
      if (this.mode == 'update') {
        this.loading = true;
        this.$apiRequest('put', `${this.baseUrl}${this.value.id}/`, { 
          data: this.form,
        }).then((res) => {
          this.$store.dispatch('alert/alert', {
            type: "info",
            message: this.$t("Registro modificado con éxito")
          });
          this.$emit("done", res.data);
        }).finally(() => {
          this.loading = false;
          this.dialog = false;
          this.$emit("close");
          this.fillFormFromValue();
        })
      } else if (this.mode == 'create') {
        this.loading = true;
        this.$apiRequest('post', this.baseUrl, { 
          data: this.form,
        }).then((res) => {
          this.$store.dispatch('alert/alert', {
            type: "info",
            message: this.$t("Registro creado con éxito")
          });
          this.$emit("done", res.data);
        }).finally(() => {
          this.loading = false;
          this.dialog = false;
          this.$emit("close");
          this.fillFormFromValue();
        })
      }
    },
    fillFormFromValue() {
      if (this.value) {
        this.form = JSON.parse(JSON.stringify(this.value));
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      // deep: true,
      handler() { this.fillFormFromValue() },
    }
  }
}