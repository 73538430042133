import Vue from 'vue'
import Router from 'vue-router'
import { store } from '../store'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Tpv from '../views/Tpv.vue'
import Compras from '../views/Compras.vue'
import Ventas from '../views/Ventas.vue'
import Inventario from '../views/Inventario.vue'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/tpv',
      name: 'tpv',
      component: Tpv,
    },
    {
      path: '/compras',
      name: 'compras',
      component: Compras,
    },
    {
      path: '/ventas',
      name: 'ventas',
      component: Ventas,
    },
    {
      path: '/inventario',
      name: 'inventario',
      component: Inventario,
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    { 
      path: "*",
      name: 'notfound', 
      component: PageNotFound
    },
  ]
});

router.beforeEach((to, from, next) => {
  let publicPages = ['/login'];
  let authRequired = !publicPages.includes(to.path);
  let loggedIn = store.getters["auth/loggedIn"];
  if (authRequired) {
    if(!loggedIn) {
      return next({name: 'login', query: {next: to.path}});
    }
  }
  return next();
})
