<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card :loading="loading">
      <v-card-title class="text-h5 mb-5">{{ $t('Actualizar PVP') }}</v-card-title>
      <v-card-text>
        <p>{{ $t('¿Quieres actualizar el precio de venta de este artículo?') }}</p>
        <v-row>
          <v-col cols="6">
            <v-text-field :label="$t('Coste')" :value="coste" readonly filled hide-details step="0.01" suffix="€" />
          </v-col>
          <v-col cols="6">
            <v-text-field :label="$t('PVP actual')" v-model="pvpActual" readonly filled hide-details step="0.01" suffix="€" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field :label="$t('Nuevo PVP')" v-model="pvp" outlined type="number" step="0.01" suffix="€" autofocus />
          </v-col>
          <v-col cols="4">
            <v-text-field :label="$t('Margen')" :value="margen" outlined type="number" suffix="%" readonly />
          </v-col>
          <v-col cols="4">
            <v-text-field :label="$t('Aumento')" :value="aumento" outlined type="number" suffix="%" readonly />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="dialog = false">{{ $t('Cerrar') }}</v-btn>
        <v-btn color="primary" text @click="submit">{{ $t('Actualizar') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ActualizarPvp",
  data: function() {
    return {
      loading: false,
      dialog: false,
      idProducto: null,
      pvpActual: null,
      coste: null,
      pvp: this.pvpActual,
    }
  },
  computed: {
    margen() {
      return this.$options.filters.round(((this.pvp - this.coste) / this.pvp) * 100);
    },
    aumento() {
      return this.$options.filters.round(((this.pvp - this.pvpActual) / this.pvpActual) * 100);
    },
  },
  methods: {
    submit() {
      this.loading = true;
      this.$apiRequest('patch', `admin/inventario/producto/${this.idProducto}/`, { 
        data: {
          pvp: this.pvp,
        },
      }).then((res) => {
          this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("Registro modificado con éxito")
          });
          this.$emit("done", res.data);
      }).finally(() => {
          this.loading = false;
          this.dialog = false;
          this.$emit("close");
      })
    }
  },
  watch: {
    pvpActual() {
      this.pvp = this.pvpActual;
    }
  }
}
</script>

<style scoped>
</style>
