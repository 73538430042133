import { apiPath } from '../config'
import { printReceipt } from '@/helpers/receiptPrinter'

export default {
  install(Vue) {
    Vue.prototype.$apiFetchAll = function(url, into, options={}) {
      return this.$apiRequest('get', url, options).then(({ data }) => {
        into.push(...data.results);
        if (data.next) {
          let relativeRoute = data.next.slice(data.next.indexOf(apiPath) + apiPath.length)
          this.$apiFetchAll(relativeRoute, into, options);
        }
      })
    }
    Vue.prototype.$printTicket = function(ticketId, gift=null) {
      return this.$apiRequest('get', `admin/ventas/ticket/${ticketId}/receipt/`, { params: { regalo: gift } }).then(({ data }) => {
        printReceipt(this.$store.state.appSettings.receiptPrinterUrl, data).catch(() => {
          this.$store.dispatch('alert/alert', {
            type: "error",
            message: this.$t("Hubo un error al imprimir el ticket. ¿Está bien configurada la impresora?"),
            timeout: 5000,
          });
        });
      })
    }
  } 
}
