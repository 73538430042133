<template>
  <v-data-table v-bind="$attrs" :loading="loading" :items="items" class="striped"
    :footer-props="{ 'items-per-page-options': [10, 20, 50], 'items-per-page-text': $t('Registros por página')}"
    :no-data-text="$t('No hay datos disponibles')"
    @click:row="(item) => $emit('read', item)"
    :options.sync="options" :server-items-length="totalItems" :items-per-page="itemsPerPage" >
    <template #top>
      <div class="d-flex align-center">
        <v-text-field v-model="search" v-if="searchable"
          :label="$t('Buscar')"
          append-icon="mdi-magnify"
          filled dense hide-details />
        <slot name="extra-filters" />
        <v-btn x-large outlined color="primary" @click="$emit('create')">{{ $t('Nuevo') }}</v-btn>
      </div>
      <slot name="dialogs" />
      <slot name="create-dialog" />
      <slot name="read-dialog" />
      <slot name="update-dialog" />
      <slot name="delete-dialog">
        <v-dialog v-model="closeDialog" max-width="400">
          <v-card :loading="dialogLoading">
            <v-card-title class="text-h5 mb-5">{{ $t('¿Borrar?') }}</v-card-title>
            <v-card-text>
              <p>{{ $t("Borrar un registro lo hace desaparecer como si nunca hubiera existido. Esto puede afectar también a registros relacionados del pasado que éste pudiera tener.") }}
              <p>{{ $t("En general, no es buena idea borrar registros de la base de datos a menos que se haya tratado de un error que se deba enmendar. No deberían borrarse registros sólo por estar obsoletos.") }}</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="closeDialog = false">{{ $t('Cancelar') }}</v-btn>
              <v-btn color="primary" text @click="borrar">{{ $t('Borrar') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </slot>
    </template>
    <template #[`item.actions`]="{ item }">
      <slot name="extra-actions" :item="item" />
      <v-icon v-if="editable(item)" small class="mr-2" @click.stop="$emit('update', item)">mdi-pencil</v-icon>
      <v-icon v-if="deletable(item)" small  @click.stop="selectedItem = item; closeDialog = true;">mdi-delete</v-icon>
    </template>
    <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "AdminDataTable",
  props: {
    fetchUrl: {
      type: String,
      default: 'api',    
    },
    filters: {
      type: Object,
      default() { return {} },    
    },
    searchable: {
      type: Boolean,
      default: true,   
    },
    editable: {
      type: Function,
      default: () => true,   
    },
    deletable: {
      type: Function,
      default: () => true,   
    },
    getStats: {
      type: Boolean,
      default: false,   
    },
  },
  data: function() {
    return {
      items: [],
      search: null,
      loading: false,
      totalItems: 0,
      itemsPerPage: 0,
      lastFetchTime: null,
      selectedItem: null,
      dialogLoading: false,
      closeDialog: false,
      options: {},
    }
  },
  computed: {
    filterset() {
      let orderings = [];
      if (this.options.sortBy) {
        for (var i=0;i<this.options.sortBy.length;i++) {
          orderings.push((this.options.sortDesc[i]?'':'-') + this.options.sortBy[i]);
        }
      }
      return {
        ...this.filters,
        search: this.search,
        ordering: orderings.join(","),
        page: this.options.page,
        page_size: this.options.itemsPerPage,
      }
    }
  },
  mounted() {
      this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      var thisFetchTime = this.lastFetchTime = Date.now()
      this.$apiRequest('get', this.fetchUrl, {
        params: this.filterset,
      }).then(res => {
        if (this.lastFetchTime <= thisFetchTime) {
          this.items = res.data.results;
          this.totalItems = res.data.count;
          this.itemsPerPage = res.data.results.length;7
        }
      }).finally(() => (this.loading = false))
      if (this.getStats) {
        this.$apiRequest('get', this.fetchUrl + "stats/", {
          params: this.filterset,
        }).then(res => {
          this.$emit("stats", res.data)
        })
      }
    },
    borrar() {
      this.dialogLoading = true;
      this.$apiRequest('delete', `${this.fetchUrl}${this.selectedItem.id}/`).then(() => {
        this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("Registro borrado con éxito")
        });
        this.fetch();
      }).finally(() => {
        this.dialogLoading = false;
        this.closeDialog = false;
      })
    },
  },
  watch: {
    filterset: {
      handler (newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          this.$nextTick(this.fetch);
        }
      },
    },
  },
}
</script>

<style scoped>
.striped >>> tbody > tr:nth-of-type(even) {
  background-color: #f8f8f8;
}
.striped >>> tbody > tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
</style>
