<template>
  <v-container fluid fill-height fill-width class="justify-center" v-if="ready">
    <v-card class="login-box pb-5 pl-5 pr-5" :loading="loading" outlined>
      <v-card-title class="d-flex flex-column justify-space-between align-center mt-5">
        <v-img alt="Colors" src="@/assets/img/logo.png" width="50%"/>
        <span class="text-h5 font-weight-light my-3">{{ $t('Inicio de sesión') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form @keyup.native.enter="handleSubmit">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field
                v-model="username"
                append-icon="mdi-account"
                :label="$t('Usuario')"
                placeholder=" "
                persistent-placeholder
                type="text" outlined
                :error="this.submitted && !this.username">
            </v-text-field>
            <v-text-field
                v-model="password"
                append-icon="mdi-lock"
                :label="$t('Contraseña')"
                placeholder=" " outlined
                persistent-placeholder
                type="password"
                :error="this.submitted && !this.password">
            </v-text-field>
            <div class="text-right">
              <a>{{ $t("¿Se te olvidó la constraseña?") }}</a>
            </div>
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-5">
        <v-btn text color="primary lighten-1" disabled>
          {{ $t('Crear cuenta') }}
        </v-btn>
        <v-spacer/>
        <v-btn color="primary" :disabled="loading" @click="handleSubmit">
          {{ $t('Iniciar sesión') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
    name: 'LoginView',
    data () {
        return {
            username: '',
            password: '',
            ready: false,
            loading: false,
            submitted: false,
        }
    },
    mounted() {
      if (this.$store.getters["auth/loggedIn"]) {
        if (this.$route.query.next) {
          this.$router.push(this.$route.query.next);
        } else {
          this.$store.dispatch("auth/logout");
        }
      }
      this.ready = true;
    },
    methods: {
      handleSubmit() {
        this.submitted = true;
        let { username, password } = this;
        if (username && password) {
          this.loading = true;
          this.$store.dispatch(
            'auth/login',
            { username, password }
          ).then((response) => {
            this.loading = false;
            if (response.status == 200) {
              this.$store.dispatch("alert/close");
              this.$router.push(this.$route.query.next || "/")
            }
          });
        }
      }
    }
};
</script>

<style scoped>
.login-box {
  min-width: 200px;
  width: 400px;
}
</style>