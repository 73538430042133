<template>
  <v-container fluid fill-width class="justify-center">
    <v-tabs v-model="tab" align-with-title>
      <v-tab key="productos">{{ $t('Artículos') }}</v-tab>
      <v-tab key="clientes">{{ $t('Clientes') }}</v-tab>
      <v-tab key="proveedores">{{ $t('Proveedores') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="productos">
        <v-card flat>
          <v-card-text><productos /></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="clientes">
        <v-card flat>
          <v-card-text><clientes /></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item key="proveedores">
        <v-card flat>
          <v-card-text><proveedores /></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Productos from '@/components/inventario/tabs/Productos.vue'
import Clientes from '@/components/inventario/tabs/Clientes.vue'
import Proveedores from '@/components/inventario/tabs/Proveedores.vue'

export default {
  name: 'Inventario',
  components: { Productos, Clientes, Proveedores, },
  data: () => {
    return {
      tab: null,
    }
  },
}
</script>

<style scoped>
</style>
