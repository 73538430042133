var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":1000},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"large":"","color":"primary","outlined":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$t("Apartados")))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('Apartados')))]),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":"","fixed-header":"","height":"400"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t('Fecha')))]),_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t('Cliente')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('Importe')))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('Pagado')))]),_c('th',{staticClass:"text-right"})])]),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id,on:{"click":function($event){return _vm.open(item)}}},[_c('td',{staticClass:"text-left font-italic font-weight-light"},[_vm._v(_vm._s(_vm._f("dateFromNow")(item.fecha)))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.cliente?item.cliente.nombre:''))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")(_vm.totalAmount(item))))]),_c('td',{staticClass:"text-center"},[(item.deposito == _vm.totalAmount(item))?_c('v-chip',{attrs:{"label":"","color":"green","dark":"","small":""}},[_vm._v(_vm._s(_vm.$t('Pagado')))]):_vm._e(),((item.deposito > 0) & (item.deposito < _vm.totalAmount(item)))?_c('span',[_vm._v(_vm._s(_vm._f("money")(item.deposito)))]):_vm._e()],1),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.loadItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-location-enter")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Cargar al TPV')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm._delete(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Borrar')))])])],1)])}),0)])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("Ok")))])],1),(_vm.selectedItem)?_c('v-dialog',{ref:"itemDialog",attrs:{"width":"600"},model:{value:(_vm.itemDialog),callback:function ($$v) {_vm.itemDialog=$$v},expression:"itemDialog"}},[_c('v-card',{staticClass:"item"},[_c('v-card-title'),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t('Artículo')))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('Cant.')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('PVP')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('Dto')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('Imp')))])])]),_c('tbody',_vm._l((_vm.selectedItem.lineas),function(sale){return _c('tr',{key:sale.id},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(sale.descripcion))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(sale.cantidad))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(sale.precio))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("percentage")(sale.descuento)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("money")((sale.precio * sale.cantidad * (100 - sale.descuento) / 100))))])])}),0)])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.itemDialog = false}}},[_vm._v(_vm._s(_vm.$t("Ok")))])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }