<template>
  <admin-data-table fetch-url="admin/inventario/producto/" ref="dataTable"
    :filters="{ familia__in: familias?familias.join(','):null }" :headers="headers"
    @create="readonly=false; selectedItem=null; $refs.ficha.dialog=true"
    @read="(item) => {readonly=true; selectedItem=item; $refs.ficha.dialog=true;}"
    @update="(item) => {readonly=false; selectedItem=item; $refs.ficha.dialog=true;}"
    >
    <template #extra-filters>
      <v-select v-model="familias" :label="$t('Filtrar por familia')" multiple
        :items="familiaOptions" item-text="nombre" item-value="id"
        :menu-props="{ closeOnContentClick: true }"
        dense filled append-icon="mdi-tag" hide-details>
        <template #selection="{ item }">
          <v-chip small dark :color="item.color">{{ item.nombre }}</v-chip>
        </template>
      </v-select>
    </template>
    <template #[`item.familia`]="{ item }">
      <v-chip v-if="item.familia" small dark :color="item.familia.color">{{ item.familia.nombre }}</v-chip>
    </template>
    <template #[`item.pvp`]="{ item }">
      {{ item.pvp|money }}
    </template>
    <template #dialogs>
      <ficha-producto ref="ficha" :readonly="readonly" :value="selectedItem" @done="() => { $refs.dataTable.fetch() }" />
    </template>
  </admin-data-table>
</template>

<script>
import AdminDataTable from '@/components/utilities/AdminDataTable.vue'
import FichaProducto from '@/components/inventario/FichaProducto.vue'

export default {
  name: "Productos",
  components: { AdminDataTable, FichaProducto },
  data: function() {
    return {
      familias: null,
      familiaOptions: [],
      formDialog: false,
      selectedItem: null,
      readonly: false,
      headers: [
          { value: 'id', text: '#', align: 'center', sortable: false, divider: true, width: 80, },
          { value: 'descripcion', text: this.$i18n.t('Descripción'), },
          { value: 'codigo', text: this.$i18n.t('Código'), align: 'center', },
          { value: 'familia', text: this.$i18n.t('Familia'), align: 'center', sortable: false },
          { value: 'pvp', text: this.$i18n.t('PVP'), align: 'right', },
          { value: 'actions', text: '', align: 'right', sortable: false, },
      ],
    }
  },
  mounted() {
    this.$apiFetchAll("admin/inventario/familia/", this.familiaOptions);
  },
}
</script>

<style scoped>
</style>
