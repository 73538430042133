import axios from 'axios'
import { apiPath } from '../config'

export default {
  install(Vue) {
    Vue.prototype.$apiRequest = function(method, url, options={}, throwErrors=[]) {
      return axios({
        method: method,
        url: apiPath + url,
        params: options.params,
        headers: {
          ...options.headers,
          'Authorization': 'Bearer ' + this.$store.state.auth.accessToken,
          'Accept-Language': this.$root.$i18n.locale,
        },
        data: options.data
      }).catch(error => {
        if (error.response) {
          /*
          * The request was made and the server responded with a
          * status code that falls out of the range of 2xx
          */
          if (throwErrors.indexOf(error.response.status) != -1) {
            throw error;
          }
          if (error.response.status == 400) {
            // Client bad request
            this.$store.dispatch('alert/alert', {
                message: error.response.data,
                type: "error",
            });
          } else {
            // Other errors
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            this.$store.dispatch('alert/alert', {
                message: this.$t("Server Response {code} at {url}", { code: error.response.status, url: error.request.responseURL }),
                type: "error",
            });
          }
        } else if (error.request) {
          /*
              * The request was made but no response was received, `error.request`
              * is an instance of XMLHttpRequest in the browser and an instance
              * of http.ClientRequest in Node.js
              */
          console.log(error.request);
          this.$store.dispatch('alert/alert', {
            message: this.$t("Server did not respond"),
            type: "error",
          });
        } else {
          // Something happened in setting up the request and triggered an Error
          console.error(error.message);                
          this.$store.dispatch('alert/alert', {
            message: this.$t("App Error: {error}", { error: error.message }),
            type: "error",
          });
        }
        throw error;
      })
    }
    Vue.prototype.$apiFetchAll = function(url, into, options={}) {
      return this.$apiRequest('get', url, options).then(({ data }) => {
        into.push(...data.results);
        if (data.next) {
          let relativeRoute = data.next.slice(data.next.indexOf(apiPath) + apiPath.length)
          this.$apiFetchAll(relativeRoute, into, options);
        }
      })
    }
  } 
}
