<template>
  <v-card :loading="loading">
    <v-card-title class="text-h5 mb-5">{{ title }}</v-card-title>
    <v-card-text>
      <date-time-picker v-model="form.fecha" outlined :readonly="readonly" hide-details class="mb-1" />
      <tabla-lineas v-model="form.lineas" :readonly="readonly"/>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="$emit('close')">{{ $t('Cerrar') }}</v-btn>
      <v-btn color="primary" text v-if="!readonly" @click="save">{{ submitButtonText }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as moment from 'moment'; 
import FichaMixin from '@/components/utilities/mixins/FichaMixin'
import TablaLineas from "@/components/utilities/TablaLineas"
import DateTimePicker from '@/components/utilities/DateTimePicker.vue'

export default {
  name: "FichaTicket",
  mixins: [ FichaMixin ],
  components: { TablaLineas, DateTimePicker },
  data: function() {
    return {
      baseUrl: "admin/ventas/ticket/",
      objectName: this.$i18n.t("Ticket"),
      form: {
        lineas: [],
        fecha: moment().format("YYYY-MM-DD HH:mm"),
      },
    }
  },
}
</script>

<style scoped>
</style>
