import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { alert } from './alert';
import { auth } from './auth';
import { appSettings } from './appSettings';

export const store = new Vuex.Store({
    modules: {
        alert,
        auth,
        appSettings,
    }
});
