<template>
  <v-row dense>
    <v-col cols=3>
      <v-combobox
        ref="barcodeInput" filled
        v-model="barcode"
        @input="getByBarCode"
        :placeholder="$t('Cód. barras')"
        append-icon="mdi-barcode"
        :items="barcodeInputChoices"
        item-text="codigo"
        item-value="id"
        return-object
        hide-details
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-html="item.descripcion"></v-list-item-title>
            <v-list-item-subtitle class="caption">
              <v-icon small>mdi-barcode</v-icon> {{ item.codigo?item.codigo:$t('Sin código') }} - <v-chip dark :color="item.familia.color" x-small v-if="item.familia">{{ item.familia.nombre }}</v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            {{ item.pvp|money }}
          </v-list-item-action>
        </template>
      </v-combobox>
    </v-col>
    <v-col cols=9>
      <v-autocomplete
        ref="searchInput" filled
        v-model="selectedItem"
        :placeholder="$t('Buscar')"
        append-icon="mdi-magnify"
        @change="emitInput"
        :items="choices"
        :loading="loading"
        :search-input.sync="search"
        return-object
        hide-no-data
        hide-details
        no-filter
        item-text="descripcion"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content class="search-item">
            <v-list-item-title v-html="highlighted(search, item.descripcion)"></v-list-item-title>
            <v-list-item-subtitle class="caption">
              <v-icon small>mdi-barcode</v-icon> {{ item.codigo?item.codigo:$t('Sin código') }} - <v-chip dark :color="item.familia.color" x-small v-if="item.familia">{{ item.familia.nombre }}</v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            {{ item.pvp|money }}
          </v-list-item-action>
        </template>
      </v-autocomplete>
      <v-snackbar v-model="notFoundAlert" color="warning" timeout="10000" :top="true">        
          <v-icon class="mx-3" dark>mdi-alert-outline</v-icon>
          <span>{{ $t('No se ha encontrado el artículo. ¿Lo creamos ahora?') }}</span>
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="$refs.fichaProducto.dialog = true; notFoundAlert = false">{{ $t('¡Vamos!') }}</v-btn>
            <v-btn text v-bind="attrs" @click="notFoundAlert = false">{{ $t('Nah') }}</v-btn>
          </template>
      </v-snackbar>
      <ficha-producto ref="fichaProducto" :value="null" @done="(item) => { emitInput(item) }"/>
    </v-col>
  </v-row>
</template>

<script>
import FichaProducto from '@/components/inventario/FichaProducto.vue'

export default {
  name: "BuscadorProductos",
  components: { FichaProducto, },
  props: ["extra-query-params"],
  data: () => {
    return {
      barcode: null,
      search: "",
      choices: [],
      barcodeInputChoices: [],
      selectedItem: null,
      loading: false,
      lastFetchTime: null,
      notFoundAlert: false,
    }
  },
  methods: {
    reset() {
      this.$nextTick(() => {
        this.search = null;
        this.barcode = null;
        this.$nextTick(() => {
          this.selectedItem = null;
          this.choices = [];
          this.barcodeInputChoices = [];
        })
      });
    },
    highlighted(search, text) {
      var i = text.toLowerCase().indexOf(search.toLowerCase());
      if (i > -1) {
        var prev = text.slice(0, i);
        var high = text.slice(i, i + search.length);
        var post = text.slice(i + search.length);
        return `<span class="item-text">${prev}<span class="highlighted-text">${high}</span>${post}</span>`
      } else {
        return `<span class="item-text">${text}</span>`
      }
    },
    emitInput(value) {
      this.$emit("input", value);
      this.reset();
    },
    getByBarCode(value) {
      if (this.barcodeInputChoices.indexOf(value) > -1) {
        return this.emitInput(value);
      }
      return this.$apiRequest('get', 'get-product/', { params: { codigo: value } })
        .then(res => {
            const { results } = res.data;
            if (results.length == 0) {
              this.$refs.fichaProducto.form.codigo = value;
              this.notFoundAlert = true;
              this.reset();
            } else if (results.length > 1) {
              this.barcodeInputChoices = results;
            } else {
              this.emitInput(results[0]);
            }
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => (this.loading = false))
    },
  },
  watch: {
    search() {
        if (!this.search || !!this.selectedItem) return
        this.loading = true
        var thisCallTime = this.lastFetchTime = Date.now()
        this.$apiRequest('get', 'get-product/', { params: { search: this.search } })
        .then(res => {
          if (this.lastFetchTime <= thisCallTime) {
            const { results } = res.data;
            this.choices = results;
          }
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped>
.search-item >>> .highlighted-text {
  color: rgba(0,0,0,.38);
  background: #eee;
}
</style>
