<template>
  <v-container fluid fill-height fill-width class="justify-center">
    Home
  </v-container>
</template>

<script>
  export default {
    name: 'Home',
  }
</script>