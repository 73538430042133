var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('admin-data-table',{ref:"dataTable",attrs:{"fetch-url":"admin/ventas/factura/","headers":_vm.headers,"get-stats":true,"filters":{ fecha__date__gte: this.dateFrom, fecha__date__lte: this.dateTo },"editable":function (item) { return false; }},on:{"create":function($event){_vm.readonly=false; _vm.selectedItem=null; _vm.formDialog=true},"read":function (item) {_vm.readonly=true; _vm.selectedItem=item; _vm.formDialog=true;},"update":function (item) {_vm.readonly=false; _vm.selectedItem=item; _vm.formDialog=true;},"stats":function (value) { return _vm.stats = value; }},scopedSlots:_vm._u([{key:"extra-filters",fn:function(){return [_c('date-picker',{attrs:{"label":_vm.$t('Fecha desde'),"dense":"","filled":"","append-icon":"mdi-calendar","hide-details":"","clearable":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('date-picker',{attrs:{"label":_vm.$t('Fecha hasta'),"dense":"","filled":"","append-icon":"mdi-calendar","hide-details":"","clearable":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})]},proxy:true},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-italic font-weight-light"},[_vm._v(_vm._s(_vm._f("dateLong")(item.fecha)))])]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("money")(_vm.totalAmount(item))))]}},{key:"item.cliente__nombre",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.cliente.nombre))]}},{key:"item.albaranes",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.albaranes.length))]}},{key:"dialogs",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[_c('ficha-factura',{attrs:{"readonly":_vm.readonly,"value":_vm.selectedItem},on:{"close":function($event){_vm.formDialog=false},"done":function () { _vm.$refs.dataTable.fetch() }}})],1)]},proxy:true},{key:"extra-actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","href":_vm.buildDocumentUrl(item),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();return (function () {}).apply(null, arguments)}}},[_c('v-icon',{attrs:{"icon":"","small":""}},[_vm._v("mdi-file-document")])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-h6 secondary--text"},[_vm._v(_vm._s(_vm.stats.count)+" facturas")]),_c('div',{staticClass:"text-h4 primary--text"},[_vm._v(_vm._s(_vm._f("money")(_vm.stats.total)))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }