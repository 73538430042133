<template>
  <v-card :loading="loading">
    <v-card-title class="text-h5 mb-5">{{ title }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <date-picker v-model="form.fecha" outlined :readonly="readonly" :label="$t('Fecha')" append-icon="mdi-calendar" />
        </v-col>
        <v-col cols="4">
          <buscador-proveedores v-model="proveedor" outlined :readonly="readonly" />
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="form.referencia" :label="$t('Referencia')" outlined :readonly="readonly" append-icon="mdi-identifier" />
        </v-col>
      </v-row>
      <tabla-lineas v-model="lineas" :readonly="readonly"/>
      <v-textarea :label="$t('Observaciones')" v-model="form.observaciones" outlined :readonly="readonly" />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="$emit('close')">{{ $t('Cerrar') }}</v-btn>
      <v-btn color="primary" text v-if="!readonly" @click="save">{{ submitButtonText }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as moment from 'moment'
import _ from 'lodash'
import FichaMixin from '@/components/utilities/mixins/FichaMixin'
import TablaLineas from "@/components/utilities/TablaLineasCompra"
import DatePicker from '@/components/utilities/DatePicker.vue'
import BuscadorProveedores from '@/components/utilities/BuscadorProveedores.vue'

export default {
  name: "FichaAlbaran",
  mixins: [ FichaMixin ],
  components: { TablaLineas, DatePicker, BuscadorProveedores },
  data: function() {
    return {
      baseUrl: "admin/compras/albaran/",
      objectName: this.$i18n.t("Albarán"),
      proveedor: null,
      lineas: [],
      form: {
        lineas: [],
        fecha: moment().format("YYYY-MM-DD"),
        referencia: null,
        proveedor: null,
        observaciones: null,
      },
    }
  },
  methods: {
    fillFormFromValue() {
      if (this.value) {
        this.form = {
          lineas: [],
          proveedor: null,
          referencia: this.value.referencia,
          fecha: this.value.fecha,
          observaciones: this.value.observaciones,
        }
        this.proveedor = this.value.proveedor;
        this.lineas = this.value.lineas;
      } else {
        this.form = {
          lineas: [],
          fecha: moment().format("YYYY-MM-DD"),
          proveedor: null,
          referencia: null,
          observaciones: null,
        }
        this.proveedor = null;
        this.lineas = [];
      }
    }
  },
  watch: {
    proveedor() {
      if (this.proveedor) {
        this.form.proveedor = this.proveedor.id;
      }
    },
    lineas() {
      let aux = _.cloneDeep(this.lineas);
      aux.map(obj => {
        obj.producto = obj.producto.id
      })
      this.form.lineas = aux;
    }
  }
}
</script>

<style scoped>
</style>
