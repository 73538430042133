<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-fade-transition appear>
        <v-badge overlap color="primary darken-4" bottom bordered offset-x="25" :content="shortcut" v-if="shortcut">
          <v-btn large color="primary" dark class="mx-1" v-bind="attrs" v-on="on">{{ $t("Factura") }}</v-btn>
        </v-badge>
        <v-btn large color="primary" dark class="mx-1" v-bind="attrs" v-on="on" v-else>{{ $t("Factura") }}</v-btn>
      </v-fade-transition>
    </template>
    <v-card :loading="loading">
      <v-card-title>{{ $t("Factura") }}</v-card-title>
      <v-card-text>
        <buscador-clientes v-model="cliente" />
        <v-row>
          <v-col cols="12">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">{{ $t('Artículo') }}</th>
                  <th class="text-center">{{ $t('Cant.') }}</th>
                  <th class="text-right">{{ $t('PVP') }}</th>
                  <th class="text-right">{{ $t('Dto') }}</th>
                  <th class="text-right">{{ $t('Imp') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="sale in sales" :key="sale.id" >
                  <td class="text-left">{{ sale.descripcion }}</td>
                  <td class="text-center">{{ sale.cantidad }}</td>
                  <td class="text-right">{{ sale.precio }}</td>
                  <td class="text-right">{{ sale.descuento|percentage }}</td>
                  <td class="text-right">{{ (sale.precio * sale.cantidad * (100 - sale.descuento) / 100)|money }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9">
            <v-textarea :label="$t('Observaciones')" v-model="observaciones" auto-grow rows="3" />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="$t('Total')" :value="this.sales|totalAmount" outlined readonly type="number" step="0.01" suffix="€" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">{{ $t("Cancelar") }}</v-btn>
        <v-btn color="primary" text @click="submit" :disabled="!formValid">{{ $t("Confirmar") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BuscadorClientes from '@/components/utilities/BuscadorClientes.vue';

export default {
  name: "CrearAlbaran",
  props: ["sales", "shortcut", "defaults" ],
  components: { BuscadorClientes },
  data () {
    return {
      loading: false,
      dialog: false,
      cliente: this.defaults.cliente || null,
      observaciones: null,
    }
  },
  computed: {
    formValid() {
      return (this.cliente != null);
    },
  },
  methods: {
    submit () {
      this.loading = true;
      return this.$apiRequest('post', 'admin/ventas/apartado/', {
        data: {
          lineas: this.sales,
          cliente: this.cliente.id,
          observaciones: this.observaciones,
        }
      }).then(() => {
        this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("¡Listo, ya está apartado!")
        });
        this.$emit('done');
      }).catch(error => {
        this.$store.dispatch('alert/alert', {
          type: "warning",
          message: this.$t("App Error: {error}", { error: error.message }),
        });
      }).finally(() => {
        this.loading = false;
        this.dialog = false;
      })
    },
  },
}
</script>

<style scoped>
</style>
