
<template>
    <v-list-item class="px-0">
        <v-list-item-avatar>
            <v-icon large>mdi-clock-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
            <div class="text-caption">{{ day }}</div>
            <div class="text-h5">{{ time }}</div>
        </v-list-item-title>
    </v-list-item>
</template>

<script>
import * as moment from 'moment'; 

moment.locale("es");

export default {
  name: "Clock",
  data() {
    return {
      date: new Date(),
    }
  },
  mounted() {
    setInterval(() => this.date = new Date(), 1000)
  },
  computed: {
    time() {
        return moment(this.date).format('HH:mm:ss a');
    },
    day() {
        return moment(this.date).format("dddd, DD [de] MMMM")
    }
  }
}
</script>

<style scoped>
</style>