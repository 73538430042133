<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-fade-transition appear>
        <v-badge overlap color="primary darken-4" bottom bordered offset-x="25" :content="shortcut" v-if="shortcut">
          <v-btn large color="primary" dark class="mx-1" v-bind="attrs" v-on="on">{{ $t("Cerrar ticket") }}</v-btn>
        </v-badge>
        <v-btn large color="primary" dark class="mx-1" v-bind="attrs" v-on="on" v-else>{{ $t("Cerrar ticket") }}</v-btn>
      </v-fade-transition>
    </template>
    <v-card :loading="loading">
      <v-card-title></v-card-title>
      <v-card-text>{{ $t("¿Cerrar ticket?") }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">{{ $t("Cancelar") }}</v-btn>
        <v-btn color="primary" text @click="cerrarImprimir">{{ $t("Cerrar e imprimir") }}</v-btn>
        <v-btn color="primary" text @click="cerrar">{{ $t("Cerrar") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CerrarTicket",
  props: ["sales", "shortcut"],
  data: () => {
    return {
      loading: false,
      dialog: false,
    }
  },
  methods: {
    cerrar () {
      this.loading = true;
      return this.$apiRequest('post', 'admin/ventas/ticket/', {
        data: {
          lineas: this.sales,
        }
      }).then(() => {
        this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("¡Vamoooooossss! ¡Una venta más! 🎉"),
          timeout: 1000,
        });
        this.$emit('done');
      }).catch(error => {
        this.$store.dispatch('alert/alert', {
          type: "warning",
          message: this.$t("App Error: {error}", { error: error.message }),
        });
      }).finally(() => {
        this.loading = false;
        this.dialog = false;
      })
    },
    cerrarImprimir() {
      this.loading = true;
      return this.$apiRequest('post', 'admin/ventas/ticket/', {
        data: {
          lineas: this.sales,
        }
      }).then((res) => {
        this.$printTicket(res.data.id).then(() => {
          this.$store.dispatch('alert/alert', {
            type: "info",
            message: this.$t("¡Vamoooooossss! ¡Una venta más! 🎉"),
            timeout: 1000,
          });
          this.$emit('done');
          this.loading = false;
          this.dialog = false;
        })
      })
    },
  },
}
</script>

<style scoped>
</style>
